import React from 'react'

export default function ProgressBar(props) {
    const {
        width
    } = props;
  return (
    <div className='progress'><div style={{width:`${width}%`}}></div></div>
  )
}
