import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as Sentry from "@sentry/react";
import { useNavigate, useParams } from 'react-router-dom';
import GoogleMaps from '../components/GoogleMaps';
import { getGoogleMapsApiKey } from '../services/googleMaps';
import { getNextRoute } from '../services/routes';
import { getIsZipInServiceArea, getIsAddressBlocked } from '../services/zipCodes';
import { getContent } from '../services/content';
import { formatLeadDataForBackend, getRepeatLeadsInformation } from '../services/leads';
import { sendGetRequestAnalytics, sendPostRequest } from '../services/requests';
import InternalResetButton from '../components/InternalResetButton';

export default function MapsPage(props) {
    const { namingConventions } = useParams();
    const initialGoogleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_LOCAL_ENVIRONMENT_ONLY || props.appState.googleMapsApiKey;
    const initialState = {
        isLoading: !initialGoogleMapsApiKey ? true : false,
        address: '',
        zipFromGoogleMaps: '',
        cityFromGoogleMaps: '',
        streetFromGoogleMaps: '',
        stateFromGoogleMaps: '',
        lat: '',
        lng: '',
        zoom: '',
        googleMapsApiKey: initialGoogleMapsApiKey,
        error: '',
        alertZipOutsideOfServiceArea: ''
    }
    const [state, setState] = useState(initialState);
    const navigate = useNavigate();
    
    const {
        isLiveTransfer,
        isReferral,
        isInternalLiveChat
    } = props.appState;
    const isForInternalUse = isLiveTransfer || isInternalLiveChat || isReferral;

    useEffect(() => {
        if(!state.googleMapsApiKey) {
            getGoogleMapsApiKey().then(googleMapsApiKey => {
                if(!!googleMapsApiKey) {
                    setState({
                        ...state,
                        isLoading: false,
                        googleMapsApiKey
                    });
                    props.handleSetState({ googleMapsApiKey });
                } else {
                    Sentry.captureMessage("No googleMapsApiKey provided on frontend.  Skipping address page.");
                    if(isForInternalUse) {
                        setState({
                            ...state,
                            isLoading: false,
                            error: "Sorry, there was an error displaying Google Maps.  Please confirm the customer's address before proceeding."
                        });
                    } else if(process.env.REACT_APP_ENVIRONMENT !== "development" && process.env.REACT_APP_ENVIRONMENT !== "dev") {
                        handleNext();
                    } else {
                        setState({
                            ...state,
                            isLoading: false,
                            error: 'API KEY ERROR DURING DEVELOPMENT.  Note - this particular error will not display in production.  Instead, the user will be redirected immediately to the next page.'
                        });
                    }
                }
            });
        }
    }, []);

    const checkCanProceed = () => {
        let canProceed = true;
        if(!!state.zipFromGoogleMaps) {
            const isZipInServiceArea = getIsZipInServiceArea(state.zipFromGoogleMaps);
            const isAddressBlocked = getIsAddressBlocked(state.address);
            const userAcceptsOutOfServiceAreaReferral = isReferral && state.alertZipOutsideOfServiceArea;
            if(isAddressBlocked) {
                canProceed = false;
            } else if (!isZipInServiceArea && !userAcceptsOutOfServiceAreaReferral) {
                canProceed = false;
            }
        }
        return canProceed;
    }

    const handleBack = e => {
        e.preventDefault();
        if(isInternalLiveChat || isReferral) {
            sendGetRequestAnalytics('button','back from maps page',namingConventions);
            navigate(`/${namingConventions || '0'}/info${window.location.search || ''}`);
        }
    }

    const handleNext = async e => {
        !!e && e.preventDefault();
        sendGetRequestAnalytics('button','submit map info',namingConventions);
        const canProceed = checkCanProceed();
        if(canProceed) {
            props.handleSetState({
                address: state.address,
                zipFromGoogleMaps: state.zipFromGoogleMaps,
                lat: state.lat,
                lng: state.lng,
                zoom: state.zoom,
                cityFromGoogleMaps: state.cityFromGoogleMaps,
                streetFromGoogleMaps: state.streetFromGoogleMaps,
                stateFromGoogleMaps: state.stateFromGoogleMaps
            });
            // send data to backend for customer history
            setState({
                ...state,
                isLoading: true
            })
            console.log("checking for repeats!");
            let hasRepeatLeadsDisqualified = false;
            let calendarOverride = '';
            // #RepeatLeadCheck
            // const repeatLeadsInformation = await getRepeatLeadsInformation(state.phoneNumber, props.appState && props.appState.email, state.lat, state.lng)
            // .catch(error => {
            //     console.log({errorGetRepeatLeadsInformation: error});
            //     Sentry.captureException(error);
            // });
            const repeatLeadsInformation = {};
            console.log({repeatLeadsInformation});
            calendarOverride = !!repeatLeadsInformation && !!repeatLeadsInformation.data && repeatLeadsInformation.data.calendarOverride || "";
            hasRepeatLeadsDisqualified = !!repeatLeadsInformation && !!repeatLeadsInformation.data && repeatLeadsInformation.data.hasRepeatLeadsDisqualified;
            console.log({calendarOverride, hasRepeatLeadsDisqualified});

            const endpoint = process.env.REACT_APP_API_URL + "/api/leads";
            const leadData = formatLeadDataForBackend({
                ...props.appState,
                address: state.address,
                zipFromGoogleMaps: state.zipFromGoogleMaps,
                zipCode: state.zipFromGoogleMaps,
                lat: state.lat,
                lng: state.lng,
                zoom: state.zoom,
                cityFromGoogleMaps: state.cityFromGoogleMaps,
                city: state.cityFromGoogleMaps,
                streetFromGoogleMaps: state.streetFromGoogleMaps,
                street: state.streetFromGoogleMaps,
                stateFromGoogleMaps: state.stateFromGoogleMaps,
                state: state.stateFromGoogleMaps,
                hasRepeatLeadsDisqualified // to do - possibly save this on the backend.  filter out partial submissions with this tag
            });
            sendPostRequest(endpoint, {...leadData, partialSubmission: true});
            if(hasRepeatLeadsDisqualified) {
                console.log("disqualified");
                showAlertZipOutsideOfServiceArea();
            } else {
                // to do - use calendar override in the below route and also add it to App.js routes
                const route = getNextRoute(
                    {
                        currentRoute: "/maps",
                        environment: process.env.REACT_APP_LANDING_PAGE_TYPE,
                        calendarOverride
                    }
                );
                if(!!route) {
                    navigate(`/${namingConventions || '0'}${route}${window.location.search || ''}`);
                }
            }
        } else {
            showAlertZipOutsideOfServiceArea();
        }
    }

    const showAlertZipOutsideOfServiceArea = () => {
        let alertMessage = getContent('alertZipOutsideOfServiceArea', props.appState.language);
        sendGetRequestAnalytics('other','address out of service area',namingConventions);
        if(!!props.appState.isReferral) {
            alertMessage = "This zip code is outside of our service area.  Are you sure you want to proceed?"
        }
        setState({
            ...state,
            alertZipOutsideOfServiceArea: alertMessage
        });
    }

    const handleInternalSubmission = e => {
        e.preventDefault();
        console.log("handleInternalSubmission");
        setState({
            ...state,
            isLoading: true
        });
        let type = "";
        if(isInternalLiveChat) {
            type = "internal live chat";
        } else if (isLiveTransfer) {
            type = "live transfer";
        } else if (isReferral) {
            type = "referral";
        }
        const dataToSend = {
            isLiveTransfer,
            isInternalLiveChat,
            isReferral,
            firstName: props.appState.firstName,
            lastName: props.appState.lastName,
            email: props.appState.email,
            phoneNumber: props.appState.phoneNumber,
            zipCode: props.appState.zipCode,
            address: state.address,
            lat: state.lat,
            lng: state.lng,
            zipFromGoogleMapsAddress: state.zipFromGoogleMapsAddress,
            cityFromGoogleMaps: state.cityFromGoogleMaps,
            streetFromGoogleMaps: state.streetFromGoogleMaps,
            stateFromGoogleMaps: state.stateFromGoogleMaps,
            roof: props.appState.roof,
            bills: props.appState.bills,
            shade: props.appState.shade,
            credit: props.appState.credit,
            userUrl: props.appState.userUrl,
            energyConsultantId: props.appState.energyConsultantId,
            leadId: props.appState.leadId,
            teamMemberEmail: props.appState.teamMemberEmail,
            adInformation: props.appState.adInformation,
            type
        }
        const canProceed = checkCanProceed();
        if(canProceed) {
            console.log("can proceed");
            const endpoint = process.env.REACT_APP_API_URL + "/api/leads";
            console.log({dataToSend, energyConsultantId: dataToSend["energyConsultantId"]});
            const options = {
                url: endpoint,
                method: isLiveTransfer ? 'PATCH' : 'POST',
                headers: { 'x-api-key': process.env.REACT_APP_BACKEND_API_KEY },
                data: dataToSend
            }
            axios(options)
            .then(res => {
                if(!!res && !!res.data) {
                    console.log("RESPONSE RECEIVED");
                    console.log(res.data);
                    let message = 'Thank you, your customer data has been successfully submitted.';
                    if(isLiveTransfer) {
                        message = 'Thank you, your customer data should now be updated in the EC module.';
                    }
                    if(!!res.data.message) {
                        message = res.data.message;
                    }
                    setState({
                        ...state,
                        isLoading,
                        message
                    })
                } else {
                    throw Error("No res.data returned in /api/leads");
                }
            })
            .catch((err) => {
                console.log("err sendPostRequest");
                console.log(err);
                Sentry.captureException(err);
                setState({
                    ...state,
                    error: getContent('alertZipOutsideOfServiceArea', props.appState.language) + "."
                });
                return err;
            });
        } else {
            showAlertZipOutsideOfServiceArea();
        }
    }

    const setStateWithObject = (data) => {
        setState(prevState => ({
            ...prevState,
            error: '',
            ...data
        }));
    }

    const {
        address,
        error,
        alertZipOutsideOfServiceArea,
        lat,
        lng,
        googleMapsApiKey,
        isLoading,
        message,
        showCustomerData
    } = state;

    let enableNext = false;
    console.log({address, lat, lng});
    if(!googleMapsApiKey) {
        enableNext = true;
    } else if (!!address && !!lat && !!lng) {
        enableNext = true;
    }

    if(isLoading) {
        return (
            <div className='container'>
                {/* todo - replace LOADING with animation */}
                <div className='top text-center'>
                    <p>LOADING</p>
                </div>
            </div>
        )
    } else if (message) {
        return (
            <div className='container'>
                <div className='top text-center'>
                    {message}
                </div>
                {showCustomerData && (<>
                    <h5>Personal Information</h5>
                    <p>First Name: {props.appState.firstName}</p>
                    <p>Last Name: {props.appState.lastName}</p>
                    <p>Email: {props.appState.email}</p>
                    <p>Phone: {props.appState.phoneNumber}</p>

                    <h5>Qualifying Questions</h5>
                    <p>Roof: {props.appState.roof}</p>
                    <p>Bills: {props.appState.bills}</p>
                    <p>Shade: {props.appState.shade}</p>
                    <p>Credit Score: {props.appState.credit}</p>

                    <h5>Address Information</h5>
                    <p>Address: {props.appState.address}</p>
                    <p>Street: {props.appState.streetFromGoogleMaps}</p>
                    <p>City: {props.appState.cityFromGoogleMaps}</p>
                    <p>State: {props.appState.stateFromGoogleMaps}</p>
                    <p>Zip Code: {props.appState.zipFromGoogleMapsAddress}</p>
                    <p>Latitude: {props.appState.lat}</p>
                    <p>Longitude: {props.appState.lng}</p>

                </>)}
            </div>
        )
    } else {
        return (
            <>
                <div className="container">
                    <GoogleMaps
                        isLiveTransfer={isLiveTransfer}
                        isInternalLiveChat={isInternalLiveChat}
                        isReferral={isReferral}
                        submitLabel={(state.alertZipOutsideOfServiceArea && isReferral) ? 'Confirm' : 'Submit'}
                        address={address}
                        addressFromUrl={props.appState.addressFromUrl}
                        lat={lat && parseFloat(lat)}
                        lng={lng && parseFloat(lng)}
                        updateLocation={setStateWithObject}
                        googleMapsApiKey={googleMapsApiKey}
                        zoom={!!lat && !!lng && 23}
                        disabledNext={!enableNext}
                        handleNext={handleNext}
                        handleInternalSubmission={handleInternalSubmission}
                        message={error || alertZipOutsideOfServiceArea}
                        handleBack={handleBack}
                        appState={props.appState}
                    />
                </div>
                <InternalResetButton
                    isInternalLiveChat={isInternalLiveChat}
                    resetToInitialState={props.resetToInitialState}
                />
            </>
        )
    }
}
