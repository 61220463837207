import React from 'react'

export default function PageNotFound(props) {
    console.log(props.state);
    return (
        <div className="container">
            Page not found
        </div>
    )
}
