import React, { useEffect, useState } from 'react';
import * as Sentry from "@sentry/react";
import useScriptUrl from '../hooks/useScriptUrl';
import {
  getZipFromGoogleMapsAddress,
  getCityFromGoogleMapsAddress,
  getStreetFromGoogleMapsAddress,
  getStateFromGoogleMapsAddress
} from '../services/googleMaps';
import aspenRenewables from '../images/aspen-renewables.png';
import BackButton from './BackButton';
import AspenRenewablesImage from './AspenRenewablesImage';

export default function GoogleMaps(props) {
    const googleMapContainer = React.createRef();
    const {
      message,
      handleNext,
      disabledNext,
      isInternalLiveChat,
      isLiveTransfer,
      isReferral,
      submitLabel
    } = props;
    const initialState = {
      isLoading: true,
      error: '',
      hasAttemptedToInitMap: false,
      address: props.address || ''
    }
    const [state, setState] = useState(initialState);

    useScriptUrl(`https://maps.googleapis.com/maps/api/js?key=${props.googleMapsApiKey}&libraries=places&callback=handleInitGoogleMap`);
    

    window.handleInitGoogleMap = function() {
        console.log('LOADED');
        if(!!window.google && !!window.google.maps) {
            console.log("window.google.maps has loaded");
        } else {
            Sentry.captureMessage("Google Maps API has not loaded");
            setState({...state, error:"Google Maps API could not load."});
        }
        console.log(window.google);
        setState({
          ...state,
          isLoading: false
        });
        initMap();
    }

    function initMap() {
        const google = window.google;
        if (!!google) {
          const map = new google.maps.Map(googleMapContainer.current, {
            center: { lat: props.lat || 36.7857263, lng: props.lng || -119.9346459 },
            zoom: props.zoom || 6,
            mapTypeId: 'satellite',
            tilt: 0
          });
          const input = document.getElementById("pac-input");
          const options = {
            origin: map.getCenter(),
            componentRestrictions: { country: "us" },
            strictBounds: false,
            fields: ["formatted_address", "address_components", "geometry", "name"],
            types: ["address"],
            address: props.address || ''
          };
          const autocomplete = new google.maps.places.Autocomplete(input, options);
          // Bind the map's bounds (viewport) property to the autocomplete object,
          // so that the autocomplete requests use the current map bounds for the
          // bounds option in the request.
          autocomplete.bindTo("bounds", map);
          const infowindow = new google.maps.InfoWindow();
          const infowindowContent = document.getElementById("infowindow-content");
          infowindow.setContent(infowindowContent);
    
          const markerOptions = {
            map,
            anchorPoint: new google.maps.Point(0, -29),
            draggable: true
          }
          if (!!props.lat && props.lng) {
            markerOptions['position'] = { lat: props.lat, lng: props.lng }
          }
          const marker = new google.maps.Marker(markerOptions);
    
          marker.addListener("dragend", () => {
            const position = marker.getPosition();
            if (!!position) {
              const lat = position.lat();
              const lng = position.lng();
              if (!!lat && !!lng) {
                props.updateLocation({
                  lat,
                  lng
                });
              }
            }
          });
    
          autocomplete.addListener("place_changed", () => {
            infowindow.close();
            marker.setVisible(false);
            const place = autocomplete.getPlace();
    
            if (!place.geometry || !place.geometry.location) {
              // User entered the name of a Place that was not suggested and
              // pressed the Enter key, or the Place Details request failed.
              window.alert("No details found for input: '" + place.name + "'");
              return;
            }
    
            // If the place has a geometry, then present it on a map.
            if (place.geometry.viewport) {
              map.fitBounds(place.geometry.viewport);
            } else {
              map.setCenter(place.geometry.location);
              map.setZoom(30);
            }
            marker.setPosition(place.geometry.location);
            marker.setVisible(true);
            infowindowContent.children["place-name"].textContent = place.name;
            infowindowContent.children["place-address"].textContent = place.formatted_address;
            infowindow.open(map, marker);
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            const address = place.formatted_address;
            const zipFromGoogleMaps = getZipFromGoogleMapsAddress(place.address_components);
            const cityFromGoogleMaps = getCityFromGoogleMapsAddress(place.address_components);
            const streetFromGoogleMaps = getStreetFromGoogleMapsAddress(place.address_components);
            const stateFromGoogleMaps = getStateFromGoogleMapsAddress(place.address_components);
            const zoom = map.getZoom();
            const addressData = {
              address,
              zipFromGoogleMaps,
              lat,
              lng,
              zoom,
              cityFromGoogleMaps,
              streetFromGoogleMaps,
              stateFromGoogleMaps,
              hasEdits: true
            }
            setState(prevState => ({
              ...prevState,
              address,
              zipFromGoogleMaps,
              lat,
              lng,
              zoom
            }));
            props.updateLocation(addressData);
          });
        } else {
          return <p className="error">We're sorry, it looks like there has been an error.  Please reach out to support.  Thank you!</p>;
        }
      }

    function handleChange(e) {
      setState({
        ...state,
        [e.target.name]: e.target.value
      });
      if(!!e.target.name && e.target.name === "address") {
        console.log("e.target.name is address.  updating state.");
        props.updateLocation({address});
      }
    }

    function handleCopyAddress(e) {
      e.preventDefault();
      navigator.clipboard.writeText(props.addressFromUrl);
    }

    const {
      error,
      address
    } = state;

    const isForInternalUse = isLiveTransfer || isInternalLiveChat || isReferral;

    if(error) {
        return (
            <div>
                <p>Error.  Please reach out to support for assistance.  Sorry for the inconvenience!</p>
            </div>
        );
    } else {
        return (
          <>
            <AspenRenewablesImage />
            <div className="container mapContainer" >
              <div className="row justify-content-center">
                <div className="col-md-6">
                  {isLiveTransfer && (<>
                    <p className="text-center">
                      Please confirm the customer’s address is <span className="hover-pointer" onClick={handleCopyAddress}>{props.addressFromUrl}</span>, and correctly place the pin.
                    </p>
                  </>)}
                  {(isInternalLiveChat || isReferral) && (<>
                    <p className="text-center">
                      Please input the customer’s address, and correctly place the pin.
                    </p>
                  </>)}
                  {!isForInternalUse && (<>
                    <p className="text-center">
                      Make sure you can locate the home on the map. If we have trouble finding the home, we will reach out to you.
                    </p>
                  </>)}
                  {message && <p>{message}</p>}
                  <div className="mb-4">
                    <input
                      id="pac-input"
                      className="inputMap"
                      placeholder="Enter a location..."
                      value={address}
                      name="address"
                      onChange={handleChange}
                    />
                    {isForInternalUse && (<>
                      <button className="btnMap btn btn-primary" disabled={disabledNext} onClick={props.handleInternalSubmission}>{submitLabel || "Submit"}</button>
                    </>)}
                    {!isForInternalUse && (<>
                      <button className="btnMap btn btn-primary" disabled={disabledNext} onClick={handleNext}>Next</button>
                    </>)}
                  </div>
             
                  <div className="mb-4">
                    <div ref={googleMapContainer} id="googleMap" style={{ width: "100%", height: "400px" }}></div>
                    <div className='flex-center'>
                      <BackButton
                        isForInternalUse={props.appState.isInternalLiveChat || props.appState.isReferral}
                        handleBack={props.handleBack}
                      />
                    </div>
                  </div>
                  <div className="mb-4">
                    <div id="infowindow-content">
                      <span id="place-name" className=""></span>
                      <br />
                      <span id="place-address"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
    }
}
