export function getSecondsSinceTimestamp(timestamp) {
    if(!!timestamp) {
        return Math.floor((Date.now() - timestamp)/1000);
    }
}

export function getDayOfWeek(timezone = "America/Los_Angeles") {
    const today = new Date();
    const day = today.toLocaleString("en-US", {
        timeZone: timezone,
        weekday: 'long'
    });
    return day;
}

export function getCurrentHour(timezone = "America/Los_Angeles") {
    const date = new Date();
    const time = date.toLocaleTimeString("en-US", {
        timeZone: timezone
    });
    if(!!time && time.length > 0) {
        const splitTime = time.split(":");
        if(!!splitTime && splitTime.length >= 0) {
            const hour = splitTime[0];
            return hour;
        }
    }
    return '';
}
