import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

export default function CallChoicePage(props) {
  const navigate = useNavigate();
  const { namingConventions } = useParams();

  const handleRedirect = (e, route) => {
    !!e && e.preventDefault();
    if(!!route) {
      navigate(`/${namingConventions || '0'}${route}${window.location.search || ''}`);
    }
  }

  const handleChoice = (e, type) => {
    e.preventDefault();
    if(!!type) {
      if(type === "call now") {
        handleRedirect(null, '/call-now');
      } else if (type === "appointment") {
        handleRedirect(null, '/appointments');
      }
    }
  }
  return (
    <div className="container d-flex justify-content-center align-items-center callNow" >
      <div>
        <h1 className='text-center congrats'>Great!</h1>
        <p className="text-center">It looks like you are pre-qualified! We'll give you a call to complete your design and get your solar cash incentives.</p>
        <p className="text-center">
          {/* styles similar to live chat button */}
          <div className='flex-button-container'>
            <button className="btnChoice mr-10-desktop" onClick={e => handleChoice(e,"call now")}>CALL ME NOW</button>
            <button className="btnChoice ml-10-desktop" onClick={e => handleChoice(e,"appointment")}>SCHEDULE AN APPOINTMENT</button>
          </div>
        </p>
      </div>
    </div>
  )
}
