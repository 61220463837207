const formatPhone11Digits = (phone) => {
    let formattedPhone = '';
    if(!!phone) {
        const cleaned = ('' + phone).replace(/\D/g, '');
        if(!!cleaned) {
            formattedPhone = "+1" + cleaned;
        }
    }
    return formattedPhone;
}

const normalizePhoneInput = (value, previousValue) => {
    //https://stackoverflow.com/questions/55988065/react-how-to-format-phone-number-as-user-types
    // return nothing if no value
    if (!value) return value; 
  
    // only allows 0-9 inputs
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length; 
  
    if (!previousValue || value.length > previousValue.length) {
  
      // returns: "x", "xx", "xxx"
      if (cvLength < 4) return currentValue; 
  
      // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
      if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`; 
  
      // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`; 
    }
  };

module.exports = {
    formatPhone11Digits,
    normalizePhoneInput
}
