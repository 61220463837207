import axios from 'axios';
import * as Sentry from "@sentry/react";

export function sendPostRequest (endpoint, data, productionOnly) {
    const canMakeRequest = !productionOnly || (process.env.REACT_APP_ENVIRONMENT !== "development" && process.env.REACT_APP_ENVIRONMENT !== "dev");
    console.log({canMakeRequest});
    if(canMakeRequest) {
        return axios
        .post(endpoint, data, {headers: { 'x-api-key': process.env.REACT_APP_BACKEND_API_KEY }})
        .then(res => {
            if(!!res && !!res.data) {
                return res.data;
            } else {
                return res;
            }
        })
        .catch((err) => {
            console.log("err sendPostRequest");
            console.log(err);
            Sentry.captureException(err);
            return err;
        });
    }
}

function createQueryStringFromKeyValuePairs(pairs) {
    let queryString = '';
    if(!!pairs && pairs.length > 0) {
        pairs.forEach(pair => {
            if(!!pair.key || !!pair.value) {
                if(!queryString) {
                    queryString += "?";
                } else {
                    queryString += "&";
                }
                queryString += !!pair.key ? pair.key : 'unknownKeyName';
                queryString += "=";
                queryString += !!pair.value ? pair.value : 'unknownValue';
            }
        });
    }
    if(!!queryString) {
        encodeURI(queryString);
    }
    return queryString;
}

export async function sendGetRequestAnalytics (type, value, namingConventions) {
    // key: pageview
    // value: "landing"
    // key: button
    // value: ?
    // key: other
    // value: "address out of service area"
    if(process.env.REACT_APP_ANALYTICS === 'aspen') {
        console.log("sending request");
        const queryString = createQueryStringFromKeyValuePairs([{key: 'type', value: type},{key: 'value', value },{key:'environment',value:process.env.REACT_APP_ANALYTICS},{key:'namingConventions',value:namingConventions}]);
        const endpoint = `${process.env.REACT_APP_API_URL}/public/analytics` + (!!queryString ? queryString : "");
        console.log({endpoint});
        const headers = {
            'x-api-key': process.env.REACT_APP_BACKEND_API_KEY
        }
        return axios.get(endpoint, { headers })
        .then(res => {
            console.log("data received");
        })
        .catch(err => {
            console.log("err sendGetRequestAnalytics");
            console.log(err);
            Sentry.captureException(err);
            return err;
        });
    } else {
        console.log("Not sending analytics request in this environment");
    }
}

