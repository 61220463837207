import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { getNextRoute } from '../services/routes';
import { getIsZipInServiceArea } from '../services/zipCodes';
import { getContent } from '../services/content';
import ModalQualifyingQuestions from '../components/modals/ModalQualifyingQuestions';
import {
  getStateFromNamingConventions,
  getIsGeneratorOfferFromNamingConventions,
  getIsSolarBatteryOfferFromNamingConventions,
  getUtilityCompanyFromNamingConventions,
  getIsDripCampaignFromNamingConventions,
  getIs5kOfferFromNamingConventions
} from '../services/tracking';
import InternalResetButton from '../components/InternalResetButton';
import BackButton from '../components/BackButton';
import { sendGetRequestAnalytics } from '../services/requests';
import AspenRenewablesImage from '../components/AspenRenewablesImage';

export default function LandingPage(props) {
  const initialState = {
    zipCode: '',
    isValidZip: false,
    isZipCodeInServiceArea: false,
    alertZipOutsideOfServiceArea: ''
  };
  const [state, setState] = useState(initialState);
  const [isInitialBlockVisible, setIsInitialBlockVisible] = useState(true);

  useEffect(() => {
    sendGetRequestAnalytics('pageview','landing',namingConventions);
  },[]);
  const handleSubmitZip = (e) => {
    e.preventDefault();
    sendGetRequestAnalytics('other','submit zip',namingConventions);
    const isZipInServiceArea = getIsZipInServiceArea(state.zipCode);
    const userAcceptsOutOfServiceAreaReferral = isReferral && state.alertZipOutsideOfServiceArea;
    if (isZipInServiceArea || userAcceptsOutOfServiceAreaReferral) {
      setState({
        ...state,
        showModal: true,
      });
      sendGetRequestAnalytics('other','zip is in service area',namingConventions);
    } else {
      let alertMessage = getContent('alertZipOutsideOfServiceArea', props.appState.language);
      if(!!props.appState.isReferral) {
        alertMessage = "This zip code is outside of our service area.  Are you sure you want to proceed?"
      }
      setState({
        ...state,
        alertZipOutsideOfServiceArea: alertMessage,
      });
      sendGetRequestAnalytics('other','zip out of service area',namingConventions);
    }
  };

  const navigate = useNavigate();
  const { namingConventions, leadId } = useParams();
  const stateName = getStateFromNamingConventions(namingConventions);
  const isGeneratorOffer = getIsGeneratorOfferFromNamingConventions(namingConventions);
  const isSolarBatteryOffer = getIsSolarBatteryOfferFromNamingConventions(namingConventions);
  const is5kOffer = getIs5kOfferFromNamingConventions(namingConventions);
  const isDripCampaign = getIsDripCampaignFromNamingConventions(namingConventions);
  const utilityCompany = getUtilityCompanyFromNamingConventions(namingConventions);

  if (isDripCampaign) {
    return <Navigate to={`/drip-campaign/${namingConventions || "0"}/${leadId || "1"}`} replace />;
  }

  const handleNext = (data) => {
    const route = getNextRoute({ currentRoute: '/', environment: process.env.REACT_APP_LANDING_PAGE_TYPE });
    props.handleSetState({
      zipCode: state.zipCode,
      homeowner: data.homeowner,
      roof: data.roof,
      bills: data.bills,
      shade: data.shade,
      credit: data.credit,
      hasCosigner: data.hasCosigner
    });
    if (!!route) {
      navigate(`/${namingConventions || '0'}${route}${window.location.search || ''}`);
    }
  };
  const handleChangeZipCode = (e) => {
    let parsedZipCode = !!e.target.value ? e.target.value.replace(/[^\d]/g, '') : '';
    let isValidZip = false;
    if(!!parsedZipCode && parsedZipCode.length >= 5) {
      parsedZipCode = parsedZipCode.slice(0,5);
      isValidZip = true;
    }
    setState({
      [e.target.name]: parsedZipCode,
      isValidZip,
      alertZipOutsideOfServiceArea: ''
    });
  };
  const handleCloseModals = (e) => {
    e.preventDefault();
    setState({
      ...state,
      showModal: false,
    });
  };

  const {
    zipCode,
    isValidZip,
    alertZipOutsideOfServiceArea,
    showModal,
  } = state;
  const {
    isLiveTransfer,
    isReferral,
    isInternalLiveChat
} = props.appState;
  const isForInternalUse = isLiveTransfer || isInternalLiveChat || isReferral;

  const handleClick = () => {
    setIsInitialBlockVisible(false);
  };

  const handleBack = e => {
    e.preventDefault();
    if(isInternalLiveChat || isReferral) {
      navigate(`/${namingConventions || '0'}/user${window.location.search || ''}`);
    }
  }

  let title = `New ${stateName || ""} Incentive Can Save You More On Solar`;
  let offerText = `the $10,000 cash back solar stimulus`;

  if(!!isGeneratorOffer) {
    title = `New ${!!utilityCompany ? utilityCompany : "California"} Free Generator Program`;
    offerText = `the free generator solar program`;
  }

  if(!!isSolarBatteryOffer) {
    title = `New ${stateName || ""} Solar Stimulus for Whole Home Battery Backup`;
    offerText = `the whole home battery backup solar stimulus`;
  }

  if(!!is5kOffer) {
    offerText = `the $5,000 cash back solar stimulus`;
  }

  return (
    <>
      {showModal && (
        <ModalQualifyingQuestions
          isLiveTransfer={isLiveTransfer}
          isInternalLiveChat={isInternalLiveChat}
          homeownerFromUrl={props.appState.homeownerFromUrl}
          roofFromUrl={props.appState.roofFromUrl}
          billsFromUrl={props.appState.billsFromUrl}
          shadeFromUrl={props.appState.shadeFromUrl}
          creditFromUrl={props.appState.creditFromUrl}
          namingConventions={namingConventions}
          handleClose={handleCloseModals}
          handleNext={handleNext}
        />
      )}

      {(!showModal && !isForInternalUse) && (
        <div>
          <AspenRenewablesImage />
          {/* <div className="d-flex align-items-center justify-content-center top ">
            <img onClick={handleOpenAspenSite} src={aspenRenewables} alt="Aspen Renewables" className="hover-pointer" />
          </div> */}
          <div className="container landingRW d-flex align-items-center justify-content-center contentBox containerLanding">
            <div className="center text-center">
              <h4 className="landingUpdate">UPDATED FOR 2024</h4>
              <hr class="mt-2 mb-3"/>

              <h3>{title}</h3>
              <hr class="mt-3 mb-3"/>
              <p>Enter your <em><strong>ZIP CODE</strong></em> below to see if your region is eligible for {offerText}.</p>
              {alertZipOutsideOfServiceArea && <p>{alertZipOutsideOfServiceArea}</p>}
              <form onSubmit={handleSubmitZip}>
                <input
                  aria-label="Zip Code"
                  type="text"
                  name="zipCode"
                  value={zipCode}
                  onChange={handleChangeZipCode}
                  className="formInput"
                />
                <button
                  className="btnLndng btn btn-primary"
                  type="submit"
                  disabled={!isValidZip || !!alertZipOutsideOfServiceArea}
                  onClick={handleClick}
                >
                  Discover Your Savings
                </button>
              </form>
              <p className='italic'>Takes Less Than 60 Seconds!</p>
            </div>
          </div>
        </div>
      )}

      {(!showModal && !!isForInternalUse) && (
        <div>
          <div className="container landingRW d-flex align-items-center justify-content-center contentBox containerLanding">
            <div className="center text-center">
              {isInternalLiveChat && <h4 className="landingUpdate">ZIP CODE</h4>}
              {isLiveTransfer && <p>Here’s the customer-stated address: {props.appState.addressFromUrl || ''}</p>}
              <p>Please confirm the customer’s zip code and enter it below.</p>
              {alertZipOutsideOfServiceArea && <p>{alertZipOutsideOfServiceArea}</p>}
              <form onSubmit={handleSubmitZip}>
                <input
                  aria-label="Zip Code"
                  type="text"
                  name="zipCode"
                  value={zipCode}
                  onChange={handleChangeZipCode}
                  className="formInput"
                />
                <button
                  className="btnLndng btn btn-primary"
                  type="submit"
                  disabled={!isValidZip || (!!alertZipOutsideOfServiceArea && !isReferral)}
                  onClick={handleClick}
                >
                  {(!!alertZipOutsideOfServiceArea && !!isReferral) ? "Confirm" : "Next"}
                </button>
              </form>
              <BackButton
                isForInternalUse={props.appState.isInternalLiveChat || props.appState.isReferral}
                handleBack={handleBack}
              />
            </div>
          </div>
          <InternalResetButton
            isInternalLiveChat={isInternalLiveChat}
            resetToInitialState={props.resetToInitialState}
            position="bottom"
          />
        </div>
      )}
    </>
  );
}
