export function getIsLandlineFromJustCallError(errorMessage) {
    console.log("getIsLandlineFromJustCallError");
    if(!!errorMessage && !!errorMessage.toLowerCase && errorMessage.toLowerCase().includes("landline")) {
        return true;
    }
}

export function getIsPhoneUnreachableFromJustCallError(errorMessage) {
    console.log("getIsPhoneUnreachableFromJustCallError");
    if(!!errorMessage && !!errorMessage.toLowerCase && !!errorMessage.toLowerCase()) {
        if(errorMessage.toLowerCase().includes("status code 400")) {
            return true;
        }
    }
}
