function getVariablesFromCookies() {
    const variables = {
        gclidFromCookies: '',
        clientIdFromCookies: ''
    }
    const cookies = document.cookie;
    if(cookies) {
      // GCLID
      let gclidFromCookies = cookies.split('; ').find((row) => row.startsWith('_gcl_aw='));
      let splitGclidFromCookies;
      if(!!gclidFromCookies) {
        splitGclidFromCookies = gclidFromCookies.split("_gcl_aw=");
        if(!!splitGclidFromCookies && splitGclidFromCookies.length > 1) {
            variables.gclidFromCookies = splitGclidFromCookies[1];
        }
      }
      // CLIENT ID
      let clientIdFromCookies = cookies.split('; ').find((row) => row.startsWith('_ga='));
      let splitClientIdFromCookies;
      if(!!clientIdFromCookies) {
        splitClientIdFromCookies = clientIdFromCookies.split("_ga=");
        if(!!splitClientIdFromCookies && splitClientIdFromCookies.length > 1) {
            variables.clientIdFromCookies = splitClientIdFromCookies[1];
        }
      }
    }
    return variables;
}

function getValueFromUrlParameter(name) {
    if(!!name) {
        let value = '';
        if(!!window && !!window.location && !!window.location.search) {
            const urlParams = new URLSearchParams(window.location.search);
            value = urlParams.get([name]);
        }
        return value;
    }
}

function getEncodedValueFromUrlParameter(name) {
    const address = getValueFromUrlParameter(name);
    if(!!address) {
        return decodeURI(address);
    }
}

function generateNamingConventions(options = {}) {
    let namingConventions = '';
    const {
        isLiveTransfer,
        isReferral,
        isInternalLiveChat
    } = options;
    if(!!isLiveTransfer) {
        // todo - for now this has no data / naming conventions
        namingConventions = "";
    } else if (!!isInternalLiveChat) {
        namingConventions = "ASP_ASR_MLC_10K";
    } else if (isReferral) {
        namingConventions = "REF";
    }
    return namingConventions;
}

function getStateFromNamingConventions(namingConventions) {
    const data = getDataFromNamingConventions(namingConventions);
    if(!!data && data.state) {
        return data.state;
    }
}

function getIsGeneratorOfferFromNamingConventions(namingConventions) {
    const adOffer = getAdOfferFromNamingConventions(namingConventions);
    if(!!adOffer && adOffer === "Portable Generator") {
        return true;
    }
}

function getIsSolarBatteryOfferFromNamingConventions(namingConventions) {
    const adOffer = getAdOfferFromNamingConventions(namingConventions);
    if(!!adOffer && adOffer === "Solar + Battery") {
        return true;
    }
}

function getIs5kOfferFromNamingConventions(namingConventions) {
    const adOffer = getAdOfferFromNamingConventions(namingConventions);
    if(!!adOffer && adOffer === "$5,000 Cashback") {
        return true;
    }
}

function getIsDripCampaignFromNamingConventions(namingConventions) {
    // adc in naming conventions
    const leadProvider = getLeadProviderFromNamingConventions(namingConventions);
    if(!!leadProvider && leadProvider === "Aspen Drip Campaign") {
        return true;
    }
}

function getIsNerdPowerFromNamingConventions(namingConventions) {
    const assignee = getAssigneeFromNamingConventions(namingConventions);
    if(!!assignee && assignee === "Nerd Power") {
        return true;
    }
}

function getIsSolaiFromNamingConventions(namingConventions) {
    const assignee = getAssigneeFromNamingConventions(namingConventions);
    if(!!assignee && assignee === "Solai Systems") {
        return true;
    }
}

function getAssigneeFromNamingConventions(namingConventions) {
    const data = getDataFromNamingConventions(namingConventions);
    if(!!data && data.assignee) {
        return data.assignee;
    }
}

function getAdOfferFromNamingConventions(namingConventions) {
    const data = getDataFromNamingConventions(namingConventions);
    if(!!data && data.adOffer) {
        return data.adOffer;
    }
}

function getLeadProviderFromNamingConventions(namingConventions) {
    const data = getDataFromNamingConventions(namingConventions);
    if(!!data && data.leadProvider) {
        return data.leadProvider;
    }
}

function getUtilityCompanyFromNamingConventions(namingConventions) {
    const data = getDataFromNamingConventions(namingConventions);
    if(!!data && data.utilityCompany) {
        return data.utilityCompany;
    }
}

function getDataFromNamingConventions(namingConventions) {
    let leadProvider;
    let adOffer;
    let language;
    let state;
    let utilityCompany;
    let assignee;
    
    let name;
    if (!!namingConventions) {
        name = namingConventions.toLowerCase()
    }
    if (!!name) {
        // leadProvider
        if (name.includes('adc')) {
            leadProvider = 'Aspen Drip Campaign';
        }

        // adOffer
        if (name.includes('25k') || name.includes('2.5k') || name.includes('$25k') || name.includes('$2.5k') || name.includes('$2500') || name.includes('$2,500')) {
        adOffer = '$2,500 Cashback';
        } else if (name.includes('5k') || name.includes('$5,000') || name.includes('$5000')) {
        adOffer = '$5,000 Cashback';
        } else if (name.includes('10k') || name.includes('$10,000') || name.includes('$10000')) {
        adOffer = '$10,000 Cashback';
        } else if (name.includes('1k')) {
        adOffer = '$1,000 Cashback';
        } else if (name.includes('6f1')) {
        adOffer = '6 Months for $1';
        } else if (name.includes('dsy')) {
        adOffer = 'Disney World';
        } else if (name.includes('ger')) {
        adOffer = 'Portable Generator';
        } else if (name.includes('wad')) {
        adOffer = 'Washer/Dryer';
        } else if (name.includes('smr')) {
        adOffer = 'Summer Vacation';
        } else if (name.includes('btr')) {
        adOffer = 'Free Battery';
        } else if (name.includes('spb')) {
        adOffer = 'Solar + Battery';
        } else {
        adOffer = 'Standard Offer/No Offer';
        }
    
        //language
        if (name.includes('spn') || name.includes('spanish')) {
        language = 'spanish';
        } else {
        language = 'english';
        }
    
        //state
        if (name.includes('cal')) {
        state = "California";
        } else if (name.includes('mas')) {
        state = "Massachusetts";
        } else if (name.includes('noc')) {
        state = "North Carolina";
        } else if (name.includes('soc')) {
        state = "South Carolina";
        } else if (name.includes('ill')) {
        state = "Illinois";
        } else if (name.includes('arz')) {
        state = "Arizona";
        } else if (name.includes('mou')) {
        state = "Missouri";
        } else if (name.includes('vrg')) {
        state = "Virginia";
        } else if (name.includes('nev')) {
        state = "Nevada";
        } else if (name.includes('tex')) {
        state = "Texas";
        } else if (name.includes('flr')) {
        state = "Florida";
        } else if (name.includes('col')) {
        state = "Colorado";
        } else if (name.includes('uta')) {
        state = "Utah";
        } else if (name.includes('mic')) {
        state = "Michigan";
        } else if (name.includes('mry')) {
        state = "Maryland";
        } else if (name.includes('con')) {
        state = "Connecticut";
        }

        //utility company
        if (name.includes('sdge')) {
        utilityCompany = "San Diego Gas & Electric";
        } else if (name.includes('pge')) {
        utilityCompany = "Pacific Gas and Electric";
        } else if (name.includes('sce')) {
        utilityCompany = "Southern California Edison";
        } else if (name.includes('evrs')) {
        utilityCompany = "Eversource";
        } else if (name.includes('natg')) {
        utilityCompany = "National Grid";
        }
        if(name.includes("gso")) {
        assignee = "Generation Solar";
        } else if (name.includes("nrd")) {
        assignee = "Nerd Power";
        } else if (name.includes("sls")) {
        assignee = "Solai Systems";
        } else {
        assignee = "Aspen Renewables";
        }
    }
    
    return {
        leadProvider,
        adOffer,
        language,
        state,
        utilityCompany,
        assignee
    }
}

const getIsLiveTransferFromNamingConventions = () => {
    let pathname = window.location.pathname;
    if(!!pathname) {
        pathname = pathname.toLowerCase();
        if(pathname.includes("lvt")) {
            return true;
        }
    }
}

const getIsGenerationSolarAdFromNamingConventions = () => {
    let pathname = window.location.pathname;
    if(!!pathname) {
        pathname = pathname.toLowerCase();
        if(pathname.includes("gso")) {
            return true;
        }
    }
}

const getIsSolarAllianceAdFromNamingConventions = () => {
    let pathname = window.location.pathname;
    if(!!pathname) {
        pathname = pathname.toLowerCase();
        if(pathname.includes("sal")) {
            return true;
        }
    }
}

const getIsReferralFromNamingConventions = () => {
    let pathname = window.location.pathname;
    if(!!pathname) {
        pathname = pathname.toLowerCase();
        if(pathname.includes("ref")) {
            return true;
        }
    }
}

const getIsLiveChatFromNamingConventions = () => {
    let pathname = window.location.pathname;
    if(!!pathname) {
        pathname = pathname.toLowerCase();
        if(pathname.includes("mlc")) {
            return true;
        }
    }
}

const pushObjectToDataLayer = (data) => {
    if(!!data && typeof data === "object") {
        if(!!window && !!window.dataLayer && !!window.dataLayer.push && typeof window.dataLayer.push === "function") {
            window.dataLayer.push(data)
        }
    }
}

const getIsInternalPage = () => {
    const host = !!window && !!window.location && window.location.host;
    if(!!host && host.includes("internal")) {
        return true;
    }
}

module.exports = {
    getVariablesFromCookies,
    getValueFromUrlParameter,
    getEncodedValueFromUrlParameter,
    generateNamingConventions,
    getStateFromNamingConventions,
    getIsGeneratorOfferFromNamingConventions,
    getIsSolarBatteryOfferFromNamingConventions,
    getIs5kOfferFromNamingConventions,
    getIsDripCampaignFromNamingConventions,
    getIsSolaiFromNamingConventions,
    getIsNerdPowerFromNamingConventions,
    getAdOfferFromNamingConventions,
    getLeadProviderFromNamingConventions,
    getUtilityCompanyFromNamingConventions,
    pushObjectToDataLayer,
    getIsGenerationSolarAdFromNamingConventions,
    getIsSolarAllianceAdFromNamingConventions,
    getIsLiveTransferFromNamingConventions,
    getIsReferralFromNamingConventions,
    getIsLiveChatFromNamingConventions,
    getIsInternalPage
}
