import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { generateNamingConventions } from '../services/tracking';

export default function LiveChatSplashPage(props) {
  const navigate = useNavigate();
  
  const handleSubmit = e => {
    e.preventDefault();
    const isInternalLiveChat = true;
    const adInformation = generateNamingConventions({ isInternalLiveChat });
    props.handleSetState({
        isInternalLiveChat,
        adInformation
    });
    navigate(`/${adInformation || '0'}/user`);
  }

  return (
    <div>
        <div className="container landingRW d-flex align-items-center justify-content-center contentBox containerLanding">
        <div className="center text-center">
            <h4 className="landingUpdate">LIVE CHAT</h4>
            <h3>Are you currently on a live chat with a new lead?</h3>
            <button
                className="btnLndng btn btn-primary top"
                onClick={handleSubmit}
            >
                Yes
            </button>
        </div>
        </div>
    </div>
  );
}

