import { approvedZipCodes } from '../data/approvedZipCodes';

export const getIsZipInServiceArea = (zipCode) => {
    if (!!zipCode) {
      return approvedZipCodes.includes(zipCode);
    }
}

export const getIsAddressBlocked = (address) => {
  if(!!address) {
    if(
      address === "6217 Wittig Ave, Las Vegas, NV 89131, USA"
      || (address.includes("Wittig Ave") && address.includes("6217"))
      || address === "6304 Legend Falls St, North Las Vegas, NV 89081, USA"
      || (address.includes("Legend Falls") && address.includes("6304"))
      ) {
      return true;
    }
  }
}
