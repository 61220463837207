import React, { useEffect, useState } from 'react';
import * as Sentry from "@sentry/react";
import { useNavigate, useParams } from 'react-router-dom';
import { sendGetRequestAnalytics, sendPostRequest } from '../services/requests';
import { getNextRoute } from '../services/routes';
import { getSecondsSinceTimestamp } from '../services/time';
import aspenRenewables from '../images/aspen-renewables.png';
import { getAreRepsAvailable } from '../services/availability';
import { formatLeadDataForBackend } from '../services/leads';
import AspenRenewablesImage from '../components/AspenRenewablesImage';
import { getIsLandlineFromJustCallError, getIsPhoneUnreachableFromJustCallError } from '../services/justCall';

export default function OnetimePasswordPage(props) {
    const { namingConventions, calendarOverride } = useParams();
    const initialState = {
        code: '',
        isLoading: true,
        attempts: 0,
        message: '',
        lastSentOtpCode: ''
    }
    const [areRepsAvailable, setAreRepsAvailable] = useState(false);
    const [state, setState] = useState(initialState);
    const navigate = useNavigate();

    const phoneNumber = !!props.appState && props.appState.phoneNumber;
   
    useEffect(() => {
        try {
            console.log(props.appState);
            if(!phoneNumber) {
                // if there is no phone number in the app state, navigate back to the phone page
                navigate(`/${namingConventions || '0'}/phone${window.location.search || ''}`);
            } else {
                // #removeOtpWhileJustCallIsNotWorking
                // redirectToNextPage();
                handleSendOtpCode(undefined, true);
            }
            getAreRepsAvailable()
            .then(areRepsAvailableResponse => {
                setAreRepsAvailable(areRepsAvailableResponse)
            });
        } catch (error) {
            console.log("error in useEffect on OneTimePasswordPage: ");
            console.log(error);
            Sentry.captureException(error);
            redirectToNextPage();
        }
    }, []);

    const handleSendOtpCode = (e,isFirstTimeSendingOtpCode) => {
        console.log("handleSendOtpCode");
        !!e && e.preventDefault();
        const seconds = getSecondsSinceTimestamp(state.lastSentOtpCode);
        let canProceed = true;
        if(seconds == 0 || (!!seconds && seconds < 2)) {
            canProceed = false;
        }
        if(canProceed) {
            const endpoint = process.env.REACT_APP_API_URL + "/public/otp";
            sendPostRequest(endpoint, { phoneNumber }).then(response => {
                const {
                    success,
                    message
                } = response;
                const isLandline = getIsLandlineFromJustCallError(message);
                const isPhoneUnreachable = getIsPhoneUnreachableFromJustCallError(message);
                setState({
                    ...state,
                    isLoading: false,
                    lastSentOtpCode: Date.now()
                });
                if(!!success) {
                    setState({
                        ...state,
                        isLoading: false,
                        lastSentOtpCode: Date.now()
                    });
                } else if (!!isLandline) {
                    Sentry.captureException("otp creation not successful due to landline phone");
                    handleRedirect(e, "/phone-landline");
                } else if (!!isPhoneUnreachable) {
                    Sentry.captureException("otp creation not successful due to unreachable phone");
                    handleRedirect(e, "/phone-unreachable");
                } else {
                    Sentry.captureMessage("otp creation not successful due to error on backend");
                    handleRedirect(e, "/phone-other");
                }
            });
            if(isFirstTimeSendingOtpCode) {
                sendGetRequestAnalytics("button","send otp", namingConventions);
            } else {
                sendGetRequestAnalytics("button","resend otp", namingConventions);
            }
        }
    }

    const handleRedirect = (e, route) => {
        !!e && e.preventDefault();
        if(!!route) {
            navigate(`/${namingConventions || '0'}${route}${window.location.search || ''}`);
        }
    }
    
    const handleChange = e => {
        setState({
            ...state,
            code: e.target.value
        })
    }

    const handleSubmitCode = e => {
        e.preventDefault();
        if(!!state.attempts && state.attempts >= 15) {
            console.log("attempts over 15.  not proceeding.");
        } else {
            setState({
                ...state,
                isLoading: true
            });
            if(false && state.code === "123456") {
                redirectToNextPage();
            } else {
                const phoneNumber = !!props.appState && props.appState.phoneNumber;
                const endpoint = process.env.REACT_APP_API_URL + "/public/otp-verify";
                sendPostRequest(endpoint, { phoneNumber, password: state.code }).then(response => {
                    const {
                        success
                    } = response;
                    if(!!success) {
                        const endpoint = process.env.REACT_APP_API_URL + "/public/alerts/initial-lead";
                        sendPostRequest(endpoint, {}, true);
                        // send data to backend for customer history
                        const leadData = formatLeadDataForBackend(props.appState);
                        const leadDataEndpoint = process.env.REACT_APP_API_URL + "/api/leads";
                        sendPostRequest(leadDataEndpoint, {...leadData, partialSubmission: true});
                        redirectToNextPage();
                    } else {
                        setState(prevState => ({
                            ...state,
                            isLoading: false,
                            attempts: prevState.attempts + 1,
                            message: "Sorry, it looks like that code wasn't correct."
                        }))
                    }
                });
            }
        }   
    }

    const redirectToNextPage = () => {
        const route = getNextRoute(
            {
                currentRoute: "/otp",
                environment: process.env.REACT_APP_LANDING_PAGE_TYPE,
                areRepsAvailable,
                calendarOverride
            }
            );
        if(!!route) {
            navigate(`/${namingConventions || '0'}${route}${window.location.search || ''}`);
        }
    }

    const {
        code,
        isLoading,
        message
    } = state;

    if(isLoading) {
        return (
            <>
                <div className="d-flex align-items-center justify-content-center top ">
                    <img src={aspenRenewables} alt="Aspen Renewables" className="hover-pointer" />
                </div>
                <div className="otpContainer container justify-content-center align-items-center ">
                    <h5 className='congrats d-flex align-items-center justify-content-center'>Loading</h5>
                </div>
            </>
        )
    } else {
        return (
            
            <>
            <AspenRenewablesImage />

            <div className="otpContainer container justify-content-center align-items-center ">
                <h5 className='congrats d-flex align-items-center justify-content-center'>Passcode Sent To:</h5>
                {phoneNumber && <p className='center' style={{marginBottom:'0.5rem'}}>{phoneNumber}</p>}
                <p className="center">If you don't receive it shortly please try resending the code, or click the "Back" button below to be sure you entered the correct phone number.</p>
                    <form onSubmit={handleSubmitCode}>
                        <div className="row">
                            <div className="">
                                <label className="codeTxt" htmlFor="code">Enter Code Here</label>

                                <div className="formContainer d-flex align-items-center justify-content-center">
                                    <input
                                        type="text"
                                        name="code"
                                        id="code"
                                        value={code}
                                        onChange={handleChange}
                                        className="otpInput" />
                                    <button className="btnOTP btn btn-primary " type="submit">Next</button>
                                </div>
                                
                                {message && <p>{message}</p>}
                                <div className="buttosRight formLinks">
                                    <a className="hover-pointer" onClick={handleSendOtpCode}>Resend Code</a> <br></br>
                                    <a className="hover-pointer"  onClick={(e) => handleRedirect(e, '/phone')}>Back</a>
                                </div>
                            </div>
                        </div>
                    </form>
                </div></>
        )
    }
}
