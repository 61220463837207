import { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./containers/LandingPage";
import LiveChatSplashPage from "./containers/LiveChatSplashPage";
import ReferralSplashPage from "./containers/ReferralSplashPage";
import UserSelectPage from "./containers/UserSelectPage";
import MapsPage from "./containers/MapsPage";
import OnetimePasswordPage from "./containers/OneTimePasswordPage";
import PageNotFound from "./containers/PageNotFound";
import PersonalInfoPage from "./containers/PersonalInfoPage";
import PhonePage from "./containers/PhonePage";
import AvailabilityCheckPage from "./containers/AvailabilityCheckPage";
import RepsAvailablePage from "./containers/RepsAvailablePage";
import AppointmentsPage from "./containers/AppointmentsPage";
// import LiveChatPage from "./containers/LiveChatPage";
import CallNowPage from "./containers/CallNowPage";
import ThankYouPage from "./containers/ThankYouPage";
import CallChoicePage from "./containers/CallChoicePage";
import {
  getIsInternalPage,
  getIsLiveTransferFromNamingConventions,
  getIsGenerationSolarAdFromNamingConventions,
  getIsSolarAllianceAdFromNamingConventions,
  getIsLiveChatFromNamingConventions,
  getIsReferralFromNamingConventions,
  getValueFromUrlParameter,
  getEncodedValueFromUrlParameter,
  getVariablesFromCookies
} from './services/tracking';

function App() {
  const {
    gclidFromCookies,
    clientIdFromCookies
  } = getVariablesFromCookies;
  const isInternalPage = getIsInternalPage();
  // test link:
  // http://localhost:3000/lvt_testing?address=12345%20Test%20Street%2C%20Walnut%20Creek%20California%2099999&homeowner=yes&roof=Flat&bills=testbills&shade=testShade&credit=testCredit&first=TestLead&last=Testerson&email=test%40gmail.com&phone=%28555%29123-4567&energyConsultantId=4859167000066915006&leadId=bb6f1e
  const initialState = {
    isHomeowner: false,
    language: 'english',
    googleMapsApiKey: '',
    hasNewLeadRequestBeenSent: false,
    userUrl: window.location.href,
    clientId: getValueFromUrlParameter("cid") || getValueFromUrlParameter("_gcl_aw") || clientIdFromCookies,
    gclid: getValueFromUrlParameter("gclid") || getValueFromUrlParameter("_ga") || gclidFromCookies,
    isInternalPage,
    isLiveTransfer: getIsLiveTransferFromNamingConventions(),
    isInternalLiveChat: isInternalPage && getIsLiveChatFromNamingConventions(),
    isReferral: isInternalPage && getIsReferralFromNamingConventions(),
    // adInformation is used when we generate our own naming conventions.
    //    this is only used in the internal tool, embedded as an iframe in Zoho
    //    this is necessary because a static link is passed into Zoho, so we have to generate the naming conventions from options our users select (i.e. live tranfser vs. live chat)

    adInformation: '',
    addressFromUrl: getEncodedValueFromUrlParameter("address"),
    homeownerFromUrl: getEncodedValueFromUrlParameter("homeowner"),
    roofFromUrl: getEncodedValueFromUrlParameter("roof"),
    billsFromUrl: getEncodedValueFromUrlParameter("bills"),
    shadeFromUrl: getEncodedValueFromUrlParameter("shade"),
    creditFromUrl: getEncodedValueFromUrlParameter("credit"),
    firstNameFromUrl: getEncodedValueFromUrlParameter("first"),
    lastNameFromUrl: getEncodedValueFromUrlParameter("last"),
    emailFromUrl: getEncodedValueFromUrlParameter("email"),
    phoneFromUrl: getEncodedValueFromUrlParameter("phone"),
    energyConsultantId: getEncodedValueFromUrlParameter("energyConsultantId"),
    teamMemberEmail: '', // the email of the user filling out the internal form
    isGenerationSolarAd: getIsGenerationSolarAdFromNamingConventions(),
    isSolarAllianceAd: getIsSolarAllianceAdFromNamingConventions(),
    hasCosigner: false
  }

  const [state, setState] = useState(initialState);
  const handleSetState = (data) => {
    if(!!data) {
      setState({
        ...state,
        ...data
      });
    }
  }

  const resetToInitialState = () => {
    setState({
      ...initialState,
      teamMemberEmail: state.teamMemberEmail
    });
  }
  return (
    <>
    <Router>
      <Routes>
        <Route exact path='/' element={<LandingPage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        {isInternalPage && <Route exact path='/live-chat' element={<LiveChatSplashPage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />}
        {isInternalPage && <Route exact path='/referral' element={<ReferralSplashPage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />}
        {/* <Route exact path='/mdl' element={<AppointmentsPage isMetaDirectLead={true} handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} /> */}
        
        
        {/* drip campaign routes, reached through the landing page based on naming conventions */}
        <Route exact path='/drip-campaign/:namingConventions' element={<AppointmentsPage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        <Route exact path='/drip-campaign/:namingConventions/:leadId' element={<AppointmentsPage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        
        {/* also see /:namingConventions/:leadId route below */}
        <Route exact path='/:namingConventions' element={<LandingPage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        
        <Route exact path='/user' element={<UserSelectPage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        <Route exact path='/:namingConventions/user' element={<UserSelectPage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        <Route exact path='/info' element={<PersonalInfoPage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        <Route exact path='/:namingConventions/info' element={<PersonalInfoPage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        <Route exact path='/phone' element={<PhonePage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        <Route exact path='/:namingConventions/phone' element={<PhonePage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        <Route exact path='/phone-unreachable' element={<PhonePage isUnreachablePhone={true} handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        <Route exact path='/:namingConventions/phone-unreachable' element={<PhonePage isUnreachablePhone={true} handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        <Route exact path='/phone-landline' element={<PhonePage isLandline={true} handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        <Route exact path='/:namingConventions/phone-landline' element={<PhonePage isLandline={true} handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        <Route exact path='/phone-other' element={<PhonePage isOtherPhoneError={true} handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        <Route exact path='/:namingConventions/phone-other' element={<PhonePage isOtherPhoneError={true} handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        <Route exact path='/maps' element={<MapsPage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        <Route exact path='/:namingConventions/maps' element={<MapsPage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        <Route exact path='/otp' element={<OnetimePasswordPage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        <Route exact path='/otp/:calendarOverride' element={<OnetimePasswordPage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        <Route exact path='/:namingConventions/otp' element={<OnetimePasswordPage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        <Route exact path='/:namingConventions/otp/:calendarOverride' element={<OnetimePasswordPage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        <Route exact path='/availability-check' element={<AvailabilityCheckPage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        <Route exact path='/:namingConventions/availability-check' element={<AvailabilityCheckPage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        <Route exact path='/available' element={<RepsAvailablePage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        <Route exact path='/:namingConventions/available' element={<RepsAvailablePage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        <Route exact path='/appointments' element={<AppointmentsPage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        <Route exact path='/appointments-test' element={<AppointmentsPage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        <Route exact path='/appointments/:calendarOverride' element={<AppointmentsPage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        <Route exact path='/:namingConventions/appointments' element={<AppointmentsPage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        <Route exact path='/:namingConventions/appointments/:calendarOverride' element={<AppointmentsPage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        {/* <Route exact path='/chat' element={<LiveChatPage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} /> */}
        {/* <Route exact path='/:namingConventions/chat' element={<LiveChatPage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} /> */}
        <Route exact path='/choice' element={<CallChoicePage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        <Route exact path='/:namingConventions/choice' element={<CallChoicePage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        <Route exact path='/call-now' element={<CallNowPage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        <Route exact path='/:namingConventions/call-now' element={<CallNowPage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        <Route exact path='/thank-you' element={<ThankYouPage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        <Route exact path='/thank-you/:landingId' element={<ThankYouPage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        
        <Route exact path='/:namingConventions/:leadId' element={<LandingPage handleSetState={handleSetState} appState={state} resetToInitialState={resetToInitialState} />} />
        
        <Route path='*' element={<PageNotFound state={state} />} />
      </Routes>
    </Router>
    </>
  )
}

export default App;
