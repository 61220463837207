import React from 'react'

export default function Modal(props) {
  return (
    <div className="modal d-flex align-items-center justify-content-center" data-toggle="modal" className="modal-background">
        <div>
            {props.children}
        </div>
    </div>
  )
}
