import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';

export default function RepsAvailablePage(props) {
  const {
    namingConventions
  } = useParams();
    const navigate = useNavigate();
    const handleRedirect = (e, route) => {
        e.preventDefault();
        navigate(`/${namingConventions || '0'}${route}${window.location.search || ''}`);
    }
  return (
    <div>
        {/* <div><a href="#" onClick={e => handleRedirect(e, '/chat')}>Live chat</a></div> */}
        <div><a href="#" onClick={e => handleRedirect(e, '/call-now')}>Call Now</a></div>
        <div><a href="#" onClick={e => handleRedirect(e, '/appointments')}>I don't have time right now</a></div>
    </div>
  )
}
