import axios from 'axios';
import * as Sentry from "@sentry/react";
import getBoolFromStringOrBool from '../utils/strings';

export const getAreRepsAvailable = async () => {
    console.log("getting are reps available...");
    // #NoAvailabilityChecks We are currently not giving the option of calls or live chats, so this only goes to appointments
    return false;
    // get areRepsAvailable from query parameter so this can be tested easily in production.
    const urlParams = new URLSearchParams(window.location.search);
    const areRepsAvailableFromQueryParams = getBoolFromStringOrBool(urlParams.get('areRepsAvailable'));
    const shouldOverrideValue = getBoolFromStringOrBool(urlParams.get('override'));
    console.log({shouldOverrideValue, areRepsAvailableFromQueryParams });
    if(shouldOverrideValue) {
        console.log("overriding areRepsAvailable");
        return areRepsAvailableFromQueryParams;
    }
    return axios.get(`${process.env.REACT_APP_API_URL}/public/availability`, { validateStatus: false })
    .then(res => {
        console.log("about to proceed");
        if(!!res && !!res.data) {
            console.log('res.data exists');
            console.log(res.data);
            const {
                areRepsAvailable
            } = res.data;
            if(areRepsAvailable) {
               return true;
            } else {
                return false;
            }
        } else {
            console.log('nothing returned');
            return false;
        }
    })
    .catch(error => {
        console.log("there is an error");
        console.log({error});
        Sentry.captureException(error);
        console.log('error.  navigating to appointments page.');
        return false;
    });
}
