import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { formatLeadDataForBackend } from '../services/leads';
import { sendPostRequest } from '../services/requests';

export default function CallNowPage(props) {
  const navigate = useNavigate();
  const { namingConventions } = useParams();

  useEffect(() => {
    if(!props.appState.hasNewLeadRequestBeenSent) {
      const endpoint = process.env.REACT_APP_API_URL + "/api/leads";
      const leadData = formatLeadDataForBackend(props.appState, "call now");
      sendPostRequest(endpoint, leadData);
      props.handleSetState({
        hasNewLeadRequestBeenSent: true
      });
    } else {
      console.log("not creating lead");
    }
  }, []);

  const handleRedirect = (e, route) => {
    e.preventDefault();
    if(!!route) {
      navigate(`/${namingConventions || '0'}${route}${window.location.search || ''}`);
    }
}
  return (
<div className="container d-flex justify-content-center align-items-center callNow" >
  <div>
    <p className="text-center call-now-page">Thank You! We're having an agent call you right now.  You should except a call within the next minute.</p>
    {/* <p className="text-center">
      <a href="#" onClick={e => handleRedirect(e, '/appointments')}>
        If you’re not free now, click here to set a time for us to give you a call.
      </a>
    </p> */}
  </div>
</div>
  )
}
