export function getNextRoute(options) {
    let nextRoute = "";
    if(!!options) {
        const {
            currentRoute,
            areRepsAvailable,
            calendarOverride
        } = options;
        const environment = options.environment || "default";
        if(!!currentRoute) {
            if(environment === "default") {
                if(currentRoute === "/") {
                    return "/info";
                } else if (currentRoute === "/info") {
                    return "/maps";
                } else if (currentRoute === "/maps") {
                    return "/phone";
                } else if (currentRoute === "/phone") {
                    return `/otp${!!calendarOverride ? ("/" + calendarOverride) : ""}`;
                    // #removeOtpWhileJustCallIsNotWorking
                    // return "/appointments";
                } else if (currentRoute === "/otp") {
                    if(!!areRepsAvailable) {
                        return "/availability-check";
                    } else {
                        return `/appointments${!!calendarOverride ? ("/" + calendarOverride) : ""}`;
                    }
                }
            }
        }
    }
    return nextRoute;
}
