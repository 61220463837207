import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import InternalResetButton from '../components/InternalResetButton';
import BackButton from '../components/BackButton';

export default function UserSelectPage(props) {
    const navigate = useNavigate();
    const initialState = {
        teamMemberEmail: props.appState.teamMemberEmail || ''
    }

    const {
        namingConventions
    } = useParams();

    const [state, setState] = useState(initialState);

    const handleBack = e => {
        e.preventDefault();
        if(props.appState.isInternalLiveChat) {
            navigate(`/live-chat`);
        } else if (props.appState.isReferral) {
            navigate(`/referral`);
        }
    }

    const handleNext = e => {
        e.preventDefault();
        props.handleSetState({
            teamMemberEmail: state.teamMemberEmail
        });
        navigate(`/${namingConventions || '0'}/`);
    }

    const handleChange = e => {
        e.preventDefault();
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const {
        teamMemberEmail
    } = state;

    return (
        <>
            <div>
                <div className="container landingRW d-flex align-items-center justify-content-center contentBox containerLanding">
                    <div className="center text-center">
                        <h3>Please select your name from the below list</h3>
                        <select onChange={handleChange} value={teamMemberEmail} name="teamMemberEmail">
                            <option></option>
                            <option value="aaron.katz@aspenrenewables.us">Aaron Katz</option>
                            <option value="erik.roginski@aspenrenewables.us">Erik Roginski</option>
                            <option value="hassna.belglaiaa@aspenrenewables.us">Hassna Belglaiaa</option>
                            <option value="olivia.keene@aspenrenewables.us">Olivia Keene</option>
                        </select>
                        <button
                            style={{ display: "block", margin: "10px auto"}}
                            className="btnLndng btn btn-primary top"
                            onClick={handleNext}
                            disabled={!teamMemberEmail}
                        >
                            Next
                        </button>
                        <BackButton
                            isForInternalUse={props.appState.isInternalLiveChat || props.appState.isReferral}
                            handleBack={handleBack}
                        />
                    </div>
                </div>
            </div>
            <InternalResetButton
                isInternalLiveChat={props.appState.isInternalLiveChat}
                resetToInitialState={props.resetToInitialState}
                position="bottom"
            />
        </>
    );
}
