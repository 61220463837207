import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import backwardSvg from "../images/backward-step-solid.svg";
import Modal from './modals/Modal';

export default function InternalResetButton(props) {
    const initialState = {
        isConfirming: false
    }
    const [state, setState] = useState(initialState);
    const navigate = useNavigate();
    const handleReturnToStart = e => {
        e.preventDefault();
        console.log("handleReturnToStart");
        props.resetToInitialState();
        navigate(`/live-chat`);
    }
    const toggleConfirmSubmission = e => {
        e.preventDefault();
        setState({
            ...state,
            isConfirming: !state.isConfirming
        })
    }
    const {
        isConfirming
    } = state;
    if(!props.isInternalLiveChat) {
        return <></>
    }
    let classes = "container landingRW containerLanding flex-center";
    if(!!props.position) {
        if (props.position === "bottom") {
            classes += " positionBottom";
        }
    }
    return (
        <>
            <div className={classes} style={{"background":"none", "marginTop": "0px"}}>
                <button
                    className="btn btn-primary btnStartOver"
                    onClick={toggleConfirmSubmission}
                >Start Over</button>
            </div>
            {isConfirming && <div className="modal-reset">
                <button className="position-absolute top-0 end-0 btn btn-light" onClick={toggleConfirmSubmission}>X</button>
                <h1 className="congrats">RESET DATA</h1>
                <p>Are you sure you want to reset data and return to the beginning of the form?</p>
                <div>
                    <button className='mr-5' onClick={handleReturnToStart}>Yes</button>
                    <button className='ml-5' onClick={toggleConfirmSubmission}>Cancel</button>
                </div>
            </div>}
        </>
    )
}

