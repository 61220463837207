import React from 'react'

export default function BackButton(props) {

    if(!props.isForInternalUse) {
        return <></>
    } else {
        return (
          <a
              onClick={props.handleBack}
              className="btnText"
          >Go To Previous Page</a>
        )
    }
}
