import React from 'react';
import aspenRenewables from '../images/aspen-renewables.png';

export default function AspenRenewablesImage() {
    const handleOpenAspenSite = e => {
        e.preventDefault();
        window.open('https://www.aspenrenewables.us');
        }
    return (
        <div className="d-flex align-items-center justify-content-center top ">
            <img onClick={handleOpenAspenSite} src={aspenRenewables} alt="Aspen Renewables" className="hover-pointer" />
        </div>
    )
}
