import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as Sentry from "@sentry/react";
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { sendGetRequestAnalytics } from '../services/requests';
import AspenRenewablesImage from '../components/AspenRenewablesImage';
import { getIsNerdPowerFromNamingConventions } from '../services/tracking';
function ThankYouPage() {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const start = query.get("start");
  const { landingId } = useParams();

  const initialState = {
    isLoading: true,
    readableAppointmentDate: "",
    readableAppointmentTime: "",
    namingConventions: "",
    salesRep: ""
  }
  const [state, setState] = useState(initialState);

  const getDataViaLandingId = async () => {
    console.log("getDataViaLandingId");
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/public/landing/${landingId}`, { headers: { 'x-api-key': process.env.REACT_APP_BACKEND_API_KEY }});
      const salesRep = !!response.data && response.data.salesRep;
      const readableAppointmentDate = !!response.data && response.data.readableAppointmentDate;
      const readableAppointmentTime = !!response.data && response.data.readableAppointmentTime;
      const namingConventions = !!response.data && response.data.namingConventions;
      setState(prevState => ({
        ...prevState,
        salesRep,
        readableAppointmentDate,
        readableAppointmentTime,
        namingConventions,
        isLoading: false
      }));
      if(!!salesRep && !!readableAppointmentTime && !!readableAppointmentDate) {
        sendGetRequestAnalytics('response','thank you page - matching appointment');
      } else {
        sendGetRequestAnalytics('response','thank you page - no matching appointment');
      }
      sendGetRequestAnalytics('loaded','thank you page');
    } catch (error) {
      console.log({errorGetLandingInformation: error});
      Sentry.captureException(error);
      setState(prevState => ({
        ...prevState,
        isLoading: false
      }));
      sendGetRequestAnalytics('response','thank you page - error');
      sendGetRequestAnalytics('loaded','thank you page');
    }
  }
  
  useEffect(() => {
    sendGetRequestAnalytics('pageview','thank you page');
    if(landingId) {
      getDataViaLandingId();
    } else {
      setState(prevState => ({
        ...prevState,
        isLoading: false
      }));
      sendGetRequestAnalytics('response','thank you page - no landingId');
      sendGetRequestAnalytics('loaded','thank you page');
    }
    return (() => {
      console.log("useEffect cleanup");
    })
  },[]);

  const {
    isLoading,
    readableAppointmentDate,
    readableAppointmentTime,
    salesRep,
    namingConventions
  } = state;
  const isNerdPower = getIsNerdPowerFromNamingConventions(namingConventions);

  if(isLoading) {
    return (
      <div className='homeowner'>
        <div className="mt-20">
            <h2>LOADING</h2>
        </div>
      </div>
    )
  } else if (!isNerdPower && readableAppointmentTime && salesRep) {
    return (
        <>
        <AspenRenewablesImage />
        <div className='container marginDown'>
          <h2 className='d-flex align-items-center justify-content-center' style={{textAlign:'center', fontWeight:'initial', marginBottom: '20px'}}>You've Got One Last Step!</h2>
          <div className="bubble" style={{marginBottom:'20px'}}>
            <p style={{fontWeight:"bold", marginBottom: "0px"}}>Your Energy Consultant, {salesRep}, just sent you a text message. Please respond “YES” to confirm your appointment on {readableAppointmentDate} at {readableAppointmentTime}.</p>
          </div>
        </div>
      </>
    )
  } else {
    return (
      <div className='homeowner'>
        <div className="mt-20">
            <h2>Congratulations, your appointment has been scheduled!</h2>
        </div>
      </div>
    )
  }
}

export default ThankYouPage;
