import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { getAreRepsAvailable } from '../services/availability';
import { getNextRoute } from '../services/routes';
import { getIsNerdPowerFromNamingConventions, getStateFromNamingConventions, pushObjectToDataLayer } from '../services/tracking';
import aspenRenewables from '../images/aspen-renewables.png';
import { normalizePhoneInput } from '../services/phone';
import { formatLeadDataForBackend, getRepeatLeadsInformation } from '../services/leads';
import { sendPostRequest } from '../services/requests';
import AspenRenewablesImage from '../components/AspenRenewablesImage';

export default function PhonePage(props) {
    const {
        namingConventions
    } = useParams();
    const stateName = getStateFromNamingConventions(namingConventions);
    const initialState = {
        phoneNumber: '',
        isValidPhone: false,
        isLoading: true,
        areRepsAvailable: false,
        message: ''
    }
    if (!!props.isLandline) {
        initialState['message'] = "Sorry, it looks like the phone you entered is a landline.  Please enter a phone that can receive SMS messages so we can send you your one-time passcode.";
    } else if (!!props.isUnreachablePhone) {
        initialState['message'] = "Sorry, it looks like the phone you entered is unreachable.  Please enter a phone that can receive SMS messages so we can send you your one-time passcode.";
    } else if (!!props.isOtherPhoneError) {
        initialState['message'] = "Sorry, it looks like the phone you entered is unreachable.  Please double check your phone number and resubmit.";
    }
    const [state, setState] = useState(initialState);
    const [hasPhonePermission,setHasPhonePermission] = useState(false);
    const handleSetHasPhonePermission = e => {
        setHasPhonePermission(!hasPhonePermission);
    }
    const navigate = useNavigate();
    useEffect(() => {
        getAreRepsAvailable()
            .then(areRepsAvailable => {
                setState({
                    ...state,
                    areRepsAvailable: !!areRepsAvailable,
                    isLoading: false
                })
            });
    }, []);

    const handleNext = async (e) => {
        !!e && e.preventDefault();
        console.log("handleNext");
        // #RepeatLeadCheck
        // const repeatLeadsInformation = await getRepeatLeadsInformation(state.phoneNumber, props.appState && props.appState.email).catch(error => { console.log("errorGetRepeatLeadsInformation")});
        const repeatLeadsInformation = {};
        const calendarOverride = !!repeatLeadsInformation && !!repeatLeadsInformation.data && repeatLeadsInformation.data.calendarOverride || "";
        console.log({ calendarOverride });
        // #removeOtpWhileJustCallIsNotWorking
        // send partial lead submission from phone page if we skip OTP step
        // const leadData = formatLeadDataForBackend({ ...props.appState, phoneNumber: state.phoneNumber });
        // const leadDataEndpoint = process.env.REACT_APP_API_URL + "/api/leads";
        // sendPostRequest(leadDataEndpoint, {...leadData, partialSubmission: true});

        const route = getNextRoute(
            {
                currentRoute: "/phone",
                environment: process.env.REACT_APP_LANDING_PAGE_TYPE,
                areRepsAvailable: state.areRepsAvailable,
                calendarOverride
            }
        );
        pushObjectToDataLayer({ 'phone': state.phoneNumber });
        props.handleSetState({
            phoneNumber: state.phoneNumber
        });
        if (!!route) {
            navigate(`/${namingConventions || '0'}${route}${window.location.search || ''}`);
        }
    }
    const getIsPhoneValid = (phone) => {
        if (!!phone) {
            if (phone.length === 14) {
                return true;
            }
        }
    }
    const handleChangePhoneNumber = e => {
        setState(prevState => {
            const normalizedInput = normalizePhoneInput(e.target.value, prevState.phoneNumber);
            const isValidPhone = getIsPhoneValid(normalizedInput);
            return {
                ...state,
                phoneNumber: normalizedInput,
                isValidPhone
            }
        });
    }
    const handleRedirect = (e, route) => {
        e.preventDefault();
        if (!!route) {
            navigate(`/${namingConventions || '0'}${route}${window.location.search || ''}`);
        }
    }
    const isNerdPower = getIsNerdPowerFromNamingConventions(namingConventions);
    const {
        isLoading,
        phoneNumber,
        isValidPhone,
        areRepsAvailable,
        message
    } = state;
    let headerMessage = `We will give you a call ${areRepsAvailable ? "now " : ""}to set you up with the ${!!stateName ? stateName : '2024'} solar rebate!`;
    if (isNerdPower) {
        headerMessage = `We will happily walk you through everything that’s needed to get you set up with the ${!!stateName ? stateName : '2024'} solar rebate!`;
    }
    if (isLoading) {
        return (
            // todo - replace "LOADING" with circular animation
            <div>
                <p>LOADING</p>
            </div>
        )
    } else {
        return (
            <>
                <AspenRenewablesImage />
                <div className="container phoneContainer">
                    <div className="row d-flex justify-content-center align-items-center dPhone">
                        <div className="containerPhone">
                            <h4 className='Awesome'> Awesome! </h4>
                            <p className='phoneState'>{headerMessage}</p>
                            <form className="phoneForm" onSubmit={handleNext}>
                                {message && <p style={{ textAlign: 'justify' }}>{message}</p>}
                                {!message && <p style={{ textAlign: 'justify' }}>Please enter your phone here and we’ll send you a password.</p>}
                                <label className="phoneLabel colorFont" htmlFor="phoneNumber">Phone Number</label>
                                <input
                                    className="inputLabel"
                                    type="tel"
                                    aria-label="Phone number"
                                    name="phoneNumber"
                                    value={phoneNumber}
                                    onChange={handleChangePhoneNumber}
                                    id="phoneNumber"
                                />
                                <div class="form-check">
                                    <input className="form-check-input" type="checkbox" value={handleSetHasPhonePermission} id="handleSetHasPhonePermission" onChange={handleSetHasPhonePermission} />
                                    <label className="form-check-label" for="handleSetHasPhonePermission">
                                        By checking this box I authorize Aspen and its affiliates to call or text me at this number. You can opt out at any time simply by texting STOP.
                                    </label>
                                </div>
                                <button className="btnLndng btn btn-primary" type="submit" disabled={!isValidPhone || !hasPhonePermission}>Next</button>
                            </form>
                            <p className="pPhone">We need to collect a phone number to confirm it's you. <br /> The rebate assessment requires a customized site map of your home using our satellite roof mapping tech, and that cost us about $20  <br />(don't worry, we provide this service entirely free to you).</p>
                            {/* {areRepsAvailable && <p><a className="nShare" href="#" onClick={e => handleRedirect(e, '/chat')}>I don't feel comfortable sharing my number.</a></p>} */}
                        </div>
                    </div>
                </div></>
        )
    }
}
