import * as Sentry from "@sentry/react";
import axios from "axios";

export const getZipFromGoogleMapsAddress = (components) => {
    let zipCode = '';
    if(!!components && components.length > 0) {
        components.forEach(item => {
            if(!!item && !!item.types && item.types.length > 0) {
                if(item.types.includes("postal_code")) {
                    zipCode = item.long_name || item.short_name;
                }
            }
        })
    }
    return zipCode;
}

export const getCityFromGoogleMapsAddress = (components) => {
    return getComponentByNameFromGoogleMapsAddress("city", components);
}

export const getStreetFromGoogleMapsAddress = (components) => {
    const streetNumber = getComponentByNameFromGoogleMapsAddress("streetNumber", components);
    const route = getComponentByNameFromGoogleMapsAddress("route", components);
    return streetNumber + " " + route;
}

export const getStateFromGoogleMapsAddress = (components) => {
    return getComponentByNameFromGoogleMapsAddress("state", components);
}

export const getComponentByNameFromGoogleMapsAddress = (name, components) => {
    if(!!name) {
        let value = '';
        if(!!components && components.length > 0) {
            components.forEach(item => {
                if(!!item && !!item.types && item.types.length > 0) {
                    if(name === "streetNumber") {
                        if(item.types.includes("street_number")) {
                            value = item.long_name || item.short_name;
                        }
                    } else if (name === "route") {
                        if(item.types.includes("route")) {
                            value = item.long_name || item.short_name;
                        }
                    } else if (name === "state") {
                        if(item.types.includes("administrative_area_level_1")) {
                            value = item.long_name || item.short_name;
                        }
                    } else if (name === "city") {
                        if(item.types.includes("locality")) {
                            value = item.long_name || item.short_name;
                        }
                    }
                }
            })
        }
        return value;
    }
}

export const getGoogleMapsApiKey = async () => {
    try {
        console.log("getGoogleMapsApiKey");
        return axios.get(`${process.env.REACT_APP_API_URL}/public/google-maps-api-key`, { headers: { 'x-api-key': process.env.REACT_APP_BACKEND_API_KEY }})
        .then(res => {
            if(!!res && !!res.data) {
                console.log({key:res.data.googleMapsApiKey});
                return res.data.googleMapsApiKey;
            }
          }).catch(error => {
            console.log({ errorGetGoogleMapsApiKey: error });
          });
    } catch (error) {
        console.log({ errorGetGoogleMapsApiKey: error });
        Sentry.captureException(error);
    }
}
