import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import getCalendarLink from '../services/calendars';
import { formatLeadDataForBackend } from '../services/leads';
import { sendGetRequestAnalytics, sendPostRequest } from '../services/requests';
import aspenRenewables from '../images/aspen-renewables.png';
import AspenRenewablesImage from '../components/AspenRenewablesImage';
import { getIsDripCampaignFromNamingConventions, getIsNerdPowerFromNamingConventions, getIsSolaiFromNamingConventions } from '../services/tracking';

export default function AppointmentsPage(props) {
  
  const { namingConventions, leadId, calendarOverride } = useParams();
  const isDripCampaign = getIsDripCampaignFromNamingConventions(namingConventions);
  const isNerdPower = getIsNerdPowerFromNamingConventions(namingConventions);
  const isSolai = getIsSolaiFromNamingConventions(namingConventions);
  let formattedCalendarOverride = calendarOverride;
  if(!calendarOverride) {
    if(!!props.isMetaDirectLead) {
      formattedCalendarOverride = "mdl";
    } else if (!!isDripCampaign) {
      formattedCalendarOverride = "adc";
    } else if (!!isNerdPower) {
      formattedCalendarOverride = "nrd";
    } else if (!!isSolai) {
      formattedCalendarOverride = "sls";
    }
  }

  
  useEffect(() => {
    sendGetRequestAnalytics('pageview','appointments page', namingConventions);
  },[])
  // useEffect(() => {
  //   if(!props.appState.hasNewLeadRequestBeenSent) {
  //     const endpoint = process.env.REACT_APP_API_URL + "/api/leads";
  //     const leadData = formatLeadDataForBackend(props.appState, "appointment");
  //     sendPostRequest(endpoint, leadData);
  //     props.handleSetState({
  //       hasNewLeadRequestBeenSent: true
  //     });
  //   } else {
  //     console.log("not creating lead");
  //   }
  // }, []);
  const dataForCalendar = {
    ...props.appState,
    namingConventions,
    leadId,
    createLead: true
  }
  const handleOnLoad = () => {
    sendGetRequestAnalytics('loaded','ycbm iframe', namingConventions);
  }
  console.log({formattedCalendarOverride});
  const { calendarLink, calendarId } = getCalendarLink(formattedCalendarOverride || 'default', dataForCalendar);
  let beforeYouBookMessage = 'BEFORE YOU BOOK: Our consultants will reserve this time to talk with you, so please only choose a time if you intend to be available for the call (it’s a little thing but our consultants are people with families, daycare, busy lives, etc, so booking a time and not attending is really tricky for them). Thank you for your understanding!!!';
  if(isNerdPower) {
    beforeYouBookMessage = 'BEFORE YOU BOOK: Our consultants will reserve this time to meet with you at your home, so please only choose a time if you intend to be available (it’s a little thing but our consultants are people with families, daycare, busy lives, etc, so booking a time and not attending is really tricky for them). Thank you for your understanding!!!'
  }
  return (
    <>
        <AspenRenewablesImage />
        <div className='container marginDown'>
        <h5 className='d-flex align-items-center justify-content-center' style={{textAlign:'center', fontWeight:'initial', marginBottom: '20px'}}>Please Choose A Time For One Of Our Energy Consultants To Call To Complete The Solar Incentive Enrollment</h5>
        <div className="bubble" style={{marginBottom:'20px'}}>
          <p style={{fontWeight:"bold", marginBottom: "0px"}}>{beforeYouBookMessage}</p>
        </div>
        <iframe
          onLoad={handleOnLoad}
          src={calendarLink}
          id={calendarId}
          style={{ width: "100%", minHeight: '1290px', border: '0px', backgroundColor: "transparent" }}
          scrolling="no"
          seamless="seamless"
          frameBorder="0"
          allowtransparency="true"
          title="Aspen Renewables" />
      </div></>
  )
}
