import React, { useEffect, useState } from 'react'
import Modal from './Modal'
import ProgressBar from '../ProgressBar';
import { sendGetRequestAnalytics, sendPostRequest } from '../../services/requests';
import { getIsNerdPowerFromNamingConventions } from '../../services/tracking';

export default function ModalQualifyingQuestions(props) {
    const initialState = {
        homeowner: '',
        roof: '',
        bills: '',
        shade: '',
        credit: '',
        hasCosigner: false
    }
    const [state, setState] = useState(initialState);
    const views = ['homeowner', 'roof', 'bills', 'shade', 'credit'];
    const [view, setView] = useState(views[0]);
    const [subView, setSubView] = useState("");
    const [message, setMessage] = useState('');
    const isNerdPower = getIsNerdPowerFromNamingConventions(props.namingConventions);
    let progressWidth = 10;
    const index = views.indexOf(view);
    if(index === 0) {
        progressWidth = 10;
    } else if (index === views.length - 1) {
        progressWidth = 90;
    } else {
        progressWidth = Math.floor((index/views.length) * 100);
    }

    useEffect(() => {
        console.log("state.hasCosigner: " + state.hasCosigner);
        handleNext(null, "Below 640");
        setSubView("");
    }, [state.hasCosigner]);

    const handleNotHomeowner = (e) => {
        e.preventDefault();
        sendGetRequestAnalytics('button','not homeowner', props.namingConventions);
        handleShowMessage(null, "We're currently only able to service homeowners. Sorry!");
    }

    const handleLowMonthlyElectricBill = (e) => {
        !!e && e.preventDefault();
        handleShowMessage(null, "We're currently only able to service homeowners with a monthly electric bill of over $100. Sorry!");
    }

    const handleShowMessage = (e, message) => {
        !!e && e.preventDefault();
        if(!!message) {
            setMessage(message);
        }
    }

    const handleCosigner = (e, hasCosigner) => {
        e.preventDefault();
        if(hasCosigner) {
            // note - see useEffect that updates on hasCosigner change
            setState({ ...state, hasCosigner: true });
        } else {
            setSubView("");
            setMessage("Sorry, you don’t appear to be eligible for this offer.");
            // setMessage("We're currently only able to service homeowners with a credit score over 640. Sorry!");
        }
    }

    const handleNext = (e, value) => {
        !!e && e.preventDefault();
        if(value === "Below 640" && subView != "cosigner") {
            setSubView("cosigner");
            return;
        }
        setState({ ...state, [view]: value });
        if(!!value && value.toLowerCase().trim() === "own") {
            sendGetRequestAnalytics('button','homeowner', props.namingConventions);
            // stop sending out notification from this point
            // const endpoint = process.env.REACT_APP_API_URL + "/public/alerts/initial-lead";
            // sendPostRequest(endpoint, {}, true);
        } else {
            if(!!value) {
                sendGetRequestAnalytics('button',value, props.namingConventions);
            }
        }

        if(isNerdPower && value === "$50 - $100") {
            handleLowMonthlyElectricBill();
        } else if (views.indexOf(view) === views.length - 1) {
            props.handleNext({...state, [view]: value });
        } else {
            setView(views[views.indexOf(view) + 1]);
        }
    }

    const isForInternalUse = props.isLiveTransfer || props.isInternalLiveChat;

    return (
        <Modal data-toggle="modal">
            <div  className="homeowner"> 
            <button className="position-absolute top-0 end-0 btn btn-light" onClick={props.handleClose}>X</button>
            {!message && !subView && !isForInternalUse && (<>
                <h1 className="congrats">CONGRATS</h1>
                <p>YOUR ZIP CODE QUALIFIES TO CONTINUE</p>
                <p>Just a few more questions to see if your home qualifies...</p>
                
            </>)}
            {!message && !subView && (
                <>
                    <ProgressBar width={progressWidth} />
                </>
            )}
            {message && (<>
                <p>{message}</p>
            </>)}
            {!!subView && subView === "cosigner" && (<>
                <p>Do you have a partner, spouse, or relative with a credit score above 640 who would be able to co-sign for the solar incentives and financing?</p>
                <div className="buttonContainer" style={{marginTop:'20px', marginBottom:'0px'}}>
                    <button className="btn btn-primary inBetween tracking-own" onClick={e => handleCosigner(e, true)}>Yes, I have a co-signer</button>
                    <button className="btn btn-primary inBetween tracking-rent" onClick={e => handleCosigner(e, false)}>No</button>
                </div>
            </>)}
            {view === 'homeowner' && !message && !subView && (<>

            <div className='questioner'>
                {isForInternalUse && (
                    <>
                        <p className="paddingB">Please confirm whether the customer is a homeowner</p>
                        {/* <p>User-stated answer: <strong>{props.homeownerFromUrl}</strong></p> */}
                    </>
                )}
                {!isForInternalUse && (
                    <>
                        <p className="paddingB">Are you a homeowner?</p>
                    </>
                )}
                <button className="btn btn-primary inBetween tracking-own" onClick={e => handleNext(e, 'Own')}>Own</button>
                <button className="btn btn-primary inBetween tracking-rent" onClick={handleNotHomeowner}>Rent</button>
            </div>

            </>)}
            {view === 'roof' && !message && !subView && (<>
                {isForInternalUse && (
                    <>
                        <h4 className="paddingC">Please confirm what the homeowner's roof looks like.</h4>
                        {/* <p>User-stated answer: <strong>{props.roofFromUrl}</strong></p> */}
                    </>
                )}
                {!isForInternalUse && (
                    <>
                        <h4 className="paddingC">What does your roof look like?</h4>
                    </>
                )}
                <div className="buttonContainer row align-start tracking-roof">
                        <div className="col-md-3 col-sm-6 mb-4 tracking-flat"  onClick={e => handleNext(e, 'Flat Roof')}>
                            <div className="qButton flatRoof btn btn-primary"></div>
                            <p className="textCentered">Flat Roof</p>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-4 tracking-slight" onClick={e => handleNext(e, 'Slight Pitch')}>
                            <div className="qButton slightPitch btn btn-primary" ></div>
                            <p className="textCentered">Slight Pitch</p>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-4 tracking-medium" onClick={e => handleNext(e, 'Medium Pitch')}>
                            <div className="qButton mediumPitch btn btn-primary"></div>
                            <p className="textCentered">Medium Pitch</p>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-4 tracking-steep" onClick={e => handleNext(e, 'Steep Pitch')}> 
                            <div className="qButton steepPitch btn btn-primary" ></div>
                            <p className="textCentered">Steep Pitch</p>
                        </div>
                </div>


            </>)}
            {view === 'bills' && !message && !subView && (<>
                {isForInternalUse && (
                    <>
                        <h4 className="paddingC">Please confirm the customers's monthly electric bills.</h4>
                        {/* <p>User-stated answer: <strong>{props.billsFromUrl}</strong></p> */}
                    </>
                )}
                {!isForInternalUse && (
                    <>
                        <h4 className="paddingC">How much are your monthly electricity bills?</h4>
                    </>
                )}
                <div className="buttonContainer row align-start tracking-electricity">
                        <div className="col-md-3 col-sm-6 mb-4 tracking-50-100"  onClick={e => handleNext(e, '$50 - $100')}>
                            <div className="qButton b50 btn btn-primary"></div>
                            <p className="textCentered">$50 - $100</p>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-4 tracking-101-150" onClick={e => handleNext(e, '$100 - $200')}>
                            <div className="qButton b100 btn btn-primary" ></div>
                            <p className="textCentered">$100 - $200</p>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-4 tracking-151-200" onClick={e => handleNext(e, '$200 - $300')}>
                            <div className="qButton b150 btn btn-primary"></div>
                            <p className="textCentered">$200 - $300</p>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-4 tracking-200-plus" onClick={e => handleNext(e, '$300+')}> 
                            <div className="qButton b200 btn btn-primary" ></div>
                            <p className="textCentered">$300+</p>
                        </div>
                </div>


            </>)}
            {view === 'shade' && !message && !subView && (<>
                {isForInternalUse && (
                    <>
                        <h4 className="paddingC">Please confirm how much shade the customer's roof receives.</h4>
                        {/* <p>User-stated answer: <strong>{props.shadeFromUrl}</strong></p> */}
                    </>
                )}
                {!isForInternalUse && (
                    <>
                        <h4 className="paddingC">How much shade does your roof get?</h4>
                    </>
                )}
                <div className="buttonContainer row align-start tracking-shade">
                        <div className="col-md-3 col-sm-6 mb-4 tracking-full"  onClick={e => handleNext(e, 'Full sun most of the day')}>
                            <div className="qButton fullSun btn btn-primary"></div>
                            <p className="textCentered">Full sun most of the day</p>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-4 tracking-partial" onClick={e => handleNext(e, 'Partially shaded')}>
                            <div className="qButton halfShade btn btn-primary" ></div>
                            <p className="textCentered">Partially shaded</p>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-4 tracking-mostly-shaded" onClick={e => handleNext(e, 'Shaded most of the day')}>
                            <div className="qButton fullShade btn btn-primary"></div>
                            <p className="textCentered">Shaded most of the day</p>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-4 tracking-not-sure" onClick={e => handleNext(e, 'Not sure')}> 
                            <div className="qButton notSure btn btn-primary" ></div>
                            <p className="textCentered">Not Sure</p>
                        </div>
                </div>

            </>)}
            {view === 'credit' && !message && !subView && (<>
                {isForInternalUse && (
                    <>
                        <h4 className="paddingC">Please confirm the customer's estimated credit score.</h4>
                        {/* <p>User-stated answer: <strong>{props.creditFromUrl}</strong></p> */}
                    </>
                )}
                {!isForInternalUse && (
                    <>
                        <h4 className="paddingC">What's your estimated credit score?</h4>
                    </>
                )}
                <div className="buttonContainer row align-start tracking-score">

                        <div className="col-md-3 col-sm-6 mb-4 tracking-below-640"  onClick={e => handleNext(e,  'Below 640')}>
                            <div className="qButton meter1 btn btn-primary"></div>
                            <p className="textCentered">Below-640</p>
                        </div>

                        <div className="col-md-3 col-sm-6 mb-4 tracking-641-680" onClick={e => handleNext(e, '641 - 680')}>
                            <div className="qButton meter2 btn btn-primary" ></div>
                            <p className="textCentered">641-680</p>
                        </div>

                        <div className="col-md-3 col-sm-6 mb-4 tracking-681-700" onClick={e => handleNext(e, '681 - 700')}>
                            <div className="qButton meter3 btn btn-primary"></div>
                            <p className="textCentered">681-700</p>
                        </div>

                        <div className="col-md-3 col-sm-6 mb-4 tracking-721-plus" onClick={e => handleNext(e, '721+')}> 
                            <div className="qButton meter4 btn btn-primary" ></div>
                            <p className="textCentered">721+</p>
                        </div>

                </div>

            </>)}
            </div>
        </Modal>
    )
}
