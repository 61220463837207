import { v4 as uuidv4 } from 'uuid';
import { formatPhone11Digits } from "./phone";
import { getCurrentHour, getDayOfWeek } from "./time";
import * as Sentry from "@sentry/react";

function generateLandingPageId() {
  return uuidv4() || '';
}

function getShouldShowWeekendCalendar() {
        const day = getDayOfWeek();
        const hour = getCurrentHour();
        if (day == "Friday" && hour >= 18) return true;
        if (day == "Saturday") return true;
        if (day == "Sunday" && hour <= 16) return true;
}

function getShouldShowFridayCalendar() {
  const day = getDayOfWeek();
  const hour = getCurrentHour();
  if (day == "Friday" && hour < 18) return true;
}

export default function getCalendarLink(type = 'default', data = {}) {

    let link = 'https://aspen-renewables-solar.youcanbook.me/';
    let id = 'ycbmiframeaspen-renewables-solar';
    const {
        firstName,
        lastName,
        email,
        phoneNumber,
        zipCode,
        address,
        lat,
        lng,
        zipFromGoogleMapsAddress,
        cityFromGoogleMaps,
        streetFromGoogleMaps,
        stateFromGoogleMaps,
        createLead,
        roof,
        bills,
        shade,
        credit,
        userUrl,
        gclid,
        clientId,
        namingConventions,
        leadId,
        isGenerationSolarAd,
        isSolarAllianceAd,
        hasCosigner
    } = data;
    const formattedPhone = formatPhone11Digits(phoneNumber);
    const landingPageId = generateLandingPageId();
    
    if (!!type) {
      if (type === "default") {
        const showWeekendCalendar = getShouldShowWeekendCalendar();
        const showFridayCalendar = getShouldShowFridayCalendar();
        if(showWeekendCalendar) {
          if(isGenerationSolarAd) {
            link = `https://aspen-renewables-generation-solar-weekend.youcanbook.me/`;
            id = 'ycbmiframeaspen-renewables-generation-solar-weekend';
          } else {
            // link = `https://aspen-renewables-weekend.youcanbook.me/`;
            // id = 'ycbmiframeaspen-renewables-weekend';
            // link = `https://aspen-renewables-solar.youcanbook.me/`;
            // id = 'ycbmiframeaspen-renewables-solar';
            
            // todo - remove this once the ads are fixed
            Sentry.captureMessage("Solai calendar shown under wrong naming conventions.  This is okay for now, because we have the Solai calendar displayed anyway instead of the Aspen one.  Look into this.");
            link = `https://aspen-renewables-solai-systems.youcanbook.me/`;
            id = 'ycbmiframeaspen-renewables-solai-systems';
          }
        } else if (showFridayCalendar) {
          if(isGenerationSolarAd) {
            link = `https://aspen-renewables-generation-solar-friday.youcanbook.me/`;
            id = 'ycbmiframeaspen-renewables-generation-solar-friday';
          } else {
            // link = `https://aspen-renewables-friday.youcanbook.me/`;
            // id = 'ycbmiframeaspen-renewables-friday';
            // link = `https://aspen-renewables-solar.youcanbook.me/`;
            // id = 'ycbmiframeaspen-renewables-solar';
            
            // todo - remove this once the ads are fixed
            Sentry.captureMessage("Solai calendar shown under wrong naming conventions.  This is okay for now, because we have the Solai calendar displayed anyway instead of the Aspen one.  Look into this.");
            link = `https://aspen-renewables-solai-systems.youcanbook.me/`;
            id = 'ycbmiframeaspen-renewables-solai-systems';
          }
        } else {
          if(isGenerationSolarAd) {
            link = `https://aspen-renewables-generation-solar.youcanbook.me/`;
            id = 'ycbmiframeaspen-renewables-generation-solar';
          } else {
            // link = `https://aspen-renewables-solar.youcanbook.me/`;
            // id = 'ycbmiframeaspen-renewables-solar';
            
            // todo - remove this once the ads are fixed
            Sentry.captureMessage("Solai calendar shown under wrong naming conventions.  This is okay for now, because we have the Solai calendar displayed anyway instead of the Aspen one.  Look into this.");
            link = `https://aspen-renewables-solai-systems.youcanbook.me/`;
            id = 'ycbmiframeaspen-renewables-solai-systems';
          }
        }
      } else if (type === "nrd") {
        link = `https://aspen-renewables-nerd-power.youcanbook.me/`;
        id = 'ycbmiframeaspen-renewables-nerd-power';
      } else if (type === "sls") {
        link = `https://aspen-renewables-solai-systems.youcanbook.me/`;
        id = 'ycbmiframeaspen-renewables-solai-systems';
      } else if (type === "adc") {
        // drip campaign calendars
        if(isGenerationSolarAd) {
          link = `https://aspen-renewables-generation-solar-drip-campaign.youcanbook.me/`;
          id = 'ycbmiframeaspen-renewables-generation-solar-drip-campaign';
        } else {
          console.log({isSolarAllianceAd});
          // isSolarAllianceAd also goes here, as the default assignee for drip campaign ads
          // link = `https://aspen-renewables-drip-campaign.youcanbook.me/`;
          // id = 'ycbmiframeaspen-renewables-drip-campaign';
          
          // todo - remove this once the ads are fixed
          Sentry.captureMessage("Solai calendar shown under wrong naming conventions.  This is okay for now, because we have the Solai calendar displayed anyway instead of the Aspen one.  Look into this.");
          link = `https://aspen-renewables-solai-systems.youcanbook.me/`;
          id = 'ycbmiframeaspen-renewables-solai-systems';
        }
      } else if (type === "mdl") {
        link = `https://aspen-renewables-mdl.youcanbook.me/`;
        id = 'ycbmiframeaspen-renewables-mdl';
      } else if (type === "hassna.belglaiaa") {
        link = `https://hassna-aspen-renewables.youcanbook.me/`;
        id = 'ycbmiframehassna-aspen-renewables';
      } else if (type === "michael.halona") {
        link = `https://michael-aspen-renewables.youcanbook.me/`;
        id = 'ycbmiframemichael-aspen-renewables';
      } else if (type === "nick.amy") {
        link = `https://nick-amy-aspen-renewables.youcanbook.me/`;
        id = 'ycbmiframenick-amy-aspen-renewables';
      } else if (type === "ayoube.errouihni") {
        link = `https://ayoube-errouihni-aspen-renewables.youcanbook.me/`;
        id = 'ycbmiframeayoube-errouihni-aspen-renewables';
      } else if (type === "alma.roundy") {
        link = `https://alma-roundy-aspen-renewables.youcanbook.me/`;
        id = 'ycbmiframealma-roundy-aspen-renewables';
      } else if (type === "ryan.cohn") {
        link = `https://ryan-cohn-aspen-renewables.youcanbook.me/`;
        id = 'ycbmiframeryan-cohn-aspen-renewables';
      } else if (type === "tom.abrams") {
        link = `https://tom-abrams-aspen-renewables.youcanbook.me/`;
        id = 'ycbmiframetom-abrams-aspen-renewables';
      } else if (type === "erik.roginski") {
        link = `https://erik-roginski-aspen-renewables.youcanbook.me/`;
        id = 'ycbmiframeerik-roginski-aspen-renewables';
      }
    }
    link += encodeURI(`?noframe=true&skipHeaderFooter=true&FNAME=${firstName || ''}&LNAME=${lastName || ''}&EMAIL=${email || ''}&PHONE=${formattedPhone || ''}&ZIP=${zipCode || ''}&LEADPROVIDER=${"Aspen Renewables Landing Page" || ''}&ADDRESS=${address || ''}&LAT=${lat || ''}&LNG=${lng || ''}&ZIP_GOOGLE_MAPS=${zipFromGoogleMapsAddress || ''}&STREET=${streetFromGoogleMaps || ''}&STATE=${stateFromGoogleMaps || ''}&CITY=${cityFromGoogleMaps || ''}&CREATE_LEAD=${createLead || ''}&ROOF=${roof || ''}&BILLS=${bills || ''}&SHADE=${shade || ''}&CREDIT=${credit || ''}&GCLID=${gclid || ''}&CLIENT_ID=${clientId || ''}&NAMING=${namingConventions || ''}&USER_URL=${userUrl || ''}&LEADID=${leadId || ''}&LANDING_ID=${landingPageId || ''}&HAS_COSIGNER=${hasCosigner ? "Yes" : ""}`);
    return {
        calendarLink: link,
        calendarId: id
    };
  }
