import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getAreRepsAvailable } from '../services/availability';

export default function AvailabilityCheckPage(props) {
    const navigate = useNavigate();
    const { namingConventions } = useParams(); 
    useEffect(() => {
        const phoneNumber = !!props.appState && props.appState.phoneNumber;
        if(!!phoneNumber) {
            getAreRepsAvailable()
            .then(areRepsAvailable => {
                if(areRepsAvailable) {
                    navigate(`/${namingConventions || '0'}/choice${window.location.search || ''}`);
                } else {
                    navigate(`/${namingConventions || '0'}/appointments${window.location.search || ''}`);
                }
            });
        } else {
            navigate('/phone' + window.location.search);
        }
    }, []);

    return (
        <div className='container'>
            <p>LOADING</p>
        </div>
    )
}
