import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { getNextRoute } from '../services/routes';
import aspenRenewables from '../images/aspen-renewables.png';
import { pushObjectToDataLayer } from '../services/tracking';
import { sendGetRequestAnalytics, sendPostRequest } from '../services/requests';
import { normalizePhoneInput } from '../services/phone';
import { formatLeadDataForBackend } from '../services/leads';
import InternalResetButton from '../components/InternalResetButton';
import BackButton from '../components/BackButton';
import AspenRenewablesImage from '../components/AspenRenewablesImage';

export default function PersonalInfoPage(props) {
    const { namingConventions } = useParams();
    const {
        isLiveTransfer,
        isInternalLiveChat,
        isReferral
    } = props.appState;
    const isForInternalUse = isLiveTransfer || isInternalLiveChat || isReferral;
    const initialState = {
        firstName: isForInternalUse && props.appState.firstNameFromUrl || '',
        lastName: isForInternalUse && props.appState.lastNameFromUrl || '',
        email: isForInternalUse && props.appState.emailFromUrl || '',
        phoneNumber: isForInternalUse && props.appState.phoneFromUrl && normalizePhoneInput(props.appState.phoneFromUrl, '') || '',
    }
    const [state, setState] = useState(initialState);
    const navigate = useNavigate();

    const handleBack = e => {
        e.preventDefault();
        if(isInternalLiveChat || isReferral) {
            navigate(`/${namingConventions || '0'}/${window.location.search || ''}`);
        }
     }
   
    const handleNext = (e) => {
        e.preventDefault();
        sendGetRequestAnalytics('button','submit personal info',namingConventions);
        if(!!isForInternalUse) {
            const route = getNextRoute({ currentRoute: "/info", isLiveTransfer, isInternalLiveChat, environment: process.env.REACT_APP_LANDING_PAGE_TYPE });
            props.handleSetState({
                firstName: state.firstName,
                lastName: state.lastName,
                email: state.email,
                phoneNumber: state.phoneNumber
            });
            if(!!route) {
                navigate(`/${namingConventions || '0'}${route}${window.location.search || ''}`);
            }
        } else {
            // const endpoint = process.env.REACT_APP_API_URL + "/public/alerts/initial-lead";
            // sendPostRequest(endpoint, {}, true);
            const route = getNextRoute({ currentRoute: "/info", environment: process.env.REACT_APP_LANDING_PAGE_TYPE });
            props.handleSetState({
                firstName: state.firstName,
                lastName: state.lastName,
                email: state.email
            });
            pushObjectToDataLayer({'email':state.email});
            // send data to backend for customer history
            const endpoint = process.env.REACT_APP_API_URL + "/api/leads";
            const leadData = formatLeadDataForBackend({
                ...props.appState,
                firstName: state.firstName,
                lastName: state.lastName,
                email: state.email
            });
            sendPostRequest(endpoint, {...leadData, partialSubmission: true});
            if(!!route) {
                navigate(`/${namingConventions || '0'}${route}${window.location.search || ''}`);
            }
        }
    }
    const handleChange = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    }
    
    const getIsPhoneValid = (phone) => {
        if(!!phone) {
            if(phone.length === 14) {
                return true;
            }
        }
    }
    const handleChangePhoneNumber = e => {
        setState(prevState => {
            const normalizedInput = normalizePhoneInput(e.target.value, prevState.phoneNumber);
            const isValidPhone = getIsPhoneValid(normalizedInput);
            return {
                ...state,
                phoneNumber: normalizedInput,
                isValidPhone
            }
        });
    }

    const openInNewWindow = (e, route) => {
        e.preventDefault();
        window.open(route);
    }
   
    const {
        firstName,
        lastName,
        email,
        phoneNumber
    } = state;
    return (
        <>
            {!isForInternalUse && <AspenRenewablesImage />}

            <div className="container cntnr removeBackground">
              
                <form className="formContact" onSubmit={handleNext}> 
                    <div className="text-center ">
                        {!!isLiveTransfer && (<>
                            <h5 className="goodNews mb-4"> <b className="goodNews">Please confirm the customer-provided data below</b> </h5>
                        </>)}
                        {(!!isInternalLiveChat || !!isReferral) && (<>
                            <h5 className="goodNews mb-4"> <b className="goodNews">Please input the customer's data below</b> </h5>
                        </>)}
                        {!isForInternalUse && (<>
                            <h5 className="goodNews" > <b className="goodNews"> Good News! </b> </h5>
                            <p className='primeCandidate'>Your house is a prime candidate to go solar with new Solar Stimulus program! </p>
                        </>)}
                    </div> 
                    <label className="labelContact contactLetters" htmlFor="firstName">First Name</label>
                    <input 
                        className="inputContact"
                        type="text"
                        aria-label="First Name"
                        name="firstName"
                        id="firstName"
                        value={firstName}
                        onChange={handleChange}
                        required
                    />
                    <label className="labelContact contactLetters" htmlFor="lastName">Last Name</label>
                    <input
                        className="inputContact"
                        type="text"
                        aria-label="Last Name"
                        name="lastName"
                        id="lastName"
                        value={lastName}
                        onChange={handleChange}
                        required
                    />
                    <label className="labelContact contactLetters" htmlFor="email">Email</label>
                    <input 
                        className="inputContact"
                        type="email"
                        aria-label="Email"
                        name="email"
                        id="email"
                        value={email}
                        onChange={handleChange}
                        required
                    />
                    {isForInternalUse && (
                        <>
                        <label className="labelContact contactLetters" htmlFor="phoneNumber">Phone</label>
                        <input 
                            className="inputContact"
                            type="phone"
                            aria-label="Phone"
                            name="phoneNumber"
                            id="phoneNumber"
                            value={phoneNumber}
                            onChange={handleChangePhoneNumber}
                            required
                        />
                        </>
                    )}
                    <button className="btnLndng btn btn-primary" type="submit" disabled={!firstName || !lastName || !email || (!!isForInternalUse && !phoneNumber)}>Next</button>
                    {!isForInternalUse && (<>
                        <p className='disclaimer'>By submitting this request, you are agreeing to our <a href='#' onClick={e => openInNewWindow(e,'https://www.aspenrenewables.us/terms-of-use')}> Terms and Conditions</a>  and <a href='#' onClick={e => openInNewWindow(e,'https://www.aspenrenewables.us/privacy-policy')}> Privacy Policy </a>, and authorize us and/or our partners to email, call, leave recorded messages and/or send recurring texts (msg & data rates may apply) about our services even if your number is on any federal, state, or local do not call list. Your consent to this agreement is not required to purchase products or services. We respect your privacy. To manage your contact preferences, please refer to our <a href='#' onClick={e => openInNewWindow(e,'https://www.aspenrenewables.us/privacy-policy')}> Privacy Policy</a>.</p>
                    </>)}
                    <BackButton
                        isForInternalUse={props.appState.isInternalLiveChat || props.appState.isReferral}
                        handleBack={handleBack}
                    />
                    </form>
            </div>
            <InternalResetButton
                isInternalLiveChat={isInternalLiveChat}
                resetToInitialState={props.resetToInitialState}
                position="bottom"
            />
        </>
    )
}
