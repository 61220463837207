import axios from 'axios';

export function formatLeadDataForBackend(appState = {}, type) {
    const leadData = {
        ...appState,
        type
    }
    delete leadData.googleMapsApiKey;
    return leadData;
}

export const getRepeatLeadsInformation = async (phoneNumber, email, lat, lng) => {
    console.log("getRepeatLeadsInformation");
    const options = {
        url: `${process.env.REACT_APP_API_URL}/public/repeats`,
        method: 'POST',
        headers: { 'x-api-key': process.env.REACT_APP_BACKEND_API_KEY },
        data: { phoneNumber, email, lat, lng }
    }
    return axios(options);
}
